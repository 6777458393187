
import React from "react"

const TelSvg = () => {

    return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <defs>
            <clipPath id="clip-path">
            <rect id="Rectangle_809" data-name="Rectangle 809" width="32" height="32" transform="translate(0.477)" fill="#063a81"/>
            </clipPath>
        </defs>
        <g id="icon_tel" transform="translate(-0.477)">
            <g id="Group_910" data-name="Group 910" clipPath="url(#clip-path)">
            <path id="Path_2596" data-name="Path 2596" d="M31.611,25.576,26.617,20.57a2.6,2.6,0,0,0-3.671.07L20.43,23.161l-.5-.277A25.016,25.016,0,0,1,13.882,18.5,25.246,25.246,0,0,1,9.5,12.421c-.093-.169-.183-.332-.271-.487l1.689-1.69.83-.833a2.611,2.611,0,0,0,.067-3.679L6.817.726A2.6,2.6,0,0,0,3.146.8L1.739,2.214l.039.038A8.17,8.17,0,0,0,.618,4.3,8.5,8.5,0,0,0,.1,6.382C-.557,11.862,1.94,16.869,8.72,23.664c9.372,9.391,16.924,8.682,17.25,8.648a8.408,8.408,0,0,0,2.081-.524,8.1,8.1,0,0,0,2.039-1.158l.03.027,1.426-1.4a2.616,2.616,0,0,0,.067-3.682" transform="translate(0 0)" fill="#063a81" fillRule="evenodd"/>
            </g>
        </g>
    </svg>
    )
}

export default TelSvg
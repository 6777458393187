import React, { useState, useEffect }  from 'react'
import { Row, Col } from 'antd'
import classes from "./styles.module.css"
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons"
import i18next from 'i18next'
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../components/Helmet'
import Collapsible from 'react-collapsible';
import { Player } from 'video-react'
import { useExportToolkit } from '../../hooks/useApi'


const ExportToolkit: React.FC = () => {

  const { t } = useTranslation()

  const { isFetching, ExportToolkitData, refetch } = useExportToolkit()

  useEffect(() => {
    refetch()
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  }, [i18next.language])


  const Directory = ({ data }: any) => {

    const [view, setView] = useState<boolean>(false)

    return (
      <div className={classes.box}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Collapsible open={view} handleTriggerClick={() => setView(!view)} trigger={
              <div className={classes.divVasiko}>
                <span className={classes.rowTextPart1}>
                  <span>{data.title}</span>
                </span>
                <span className={classes.rowTextPart2}>
                  {view ?
                    <ArrowUpOutlined style={{ color: 'white', fontSize: '38px' }} />

                    :
                    <ArrowDownOutlined style={{ color: 'white', fontSize: '38px' }} />
                  }
                </span>
              </div>
            }>
              <div className={classes.collapseBackGround}>

                <Row>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <div className={classes.text}>
                      <p> {data.description} </p>
                    </div>
                  </Col>
                  {data.files.length !== 0 &&
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <div className={classes.text}>
                        Attachment(s):<br /><br />
                        {data.files.map((file: any, index: number) => {

                          let pdfName = file.url.split('/')
                          pdfName = pdfName[pdfName.length - 1]
                          return (
                            <span key={index}>
                              <a href={file.url} target="_blank" style={{ color: "#fbfcf8" }}>{pdfName}</a><br />
                            </span>
                          )
                        })}

                      </div>
                    </Col>
                  }
                  {data.links.length !== 0 &&
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <div className={classes.text}>
                        Link(s):<br /><br />
                        {data.links.map((link: any, index: number) => {
                          return (
                            <span key={index}>
                              <a href={link.url} target='_blank' style={{ color: "#fbfcf8" }}>{link.text}</a><br />
                            </span>
                          )
                        })}
                      </div>
                    </Col>
                  }
                  {data.videos.length !== 0 &&
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <div className={classes.text}>
                        Video(s):<br /><br />
                        {data.videos.map((video: any, index: number) => {
                          return (
                            <span key={index}>
                              {/* <Player
                                playsInline
                                // poster="/assets/poster.png"
                                src={video.url}
                              /> */}
                              <div style={{width: '48%', padding: '10px', display: 'inline-block'}}>
                                <Player>
                                  <source src={video.url} />
                                </Player>
                              </div>
                            </span>
                          )
                        })}
                      </div>
                    </Col>
                  }
                </Row>
              </div>

            </Collapsible>
          </Col>
        </Row>
      </div>
    )
  }


  if (isFetching || ExportToolkitData.length==0) { return <div className={classes.container}></div> }

  return (
    <>
      <HelmetComponent title='SEO Common page title' descrition='SEO Common page description' />
      <div className={classes.backgroundGrey}>
        <div className={classes.container}>
          <Row justify="center">
            <Col  xs={{span:24}} lg={{span : 20}}>
              <div className={classes.container}>
                <div className={classes.titleMain}>Export Toolkit</div>
                  { ExportToolkitData.map((data: any, index:number) => <span key={index}><Directory data={data} /></span> ) }
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default ExportToolkit

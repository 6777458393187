import React from "react"
import { useTranslation } from "react-i18next"
import Spinner from '../../components/Spinner'
import HelmetComponent from '../../components/Helmet'


// const Slider = lazy(() => import('./components/Slider'))
// const ThreeD = lazy(() => import('./components/3d'))
// const Events = lazy(() => import('./components/Events'))
// const Multimedia = lazy(() => import('./components/Multimedia'))

import Slider from './components/Slider'
import ThreeD from './components/3d'
import Events from './components/Events'
import Multimedia from './components/Multimedia'

const Home = () => {

  const { t } = useTranslation()
  
  window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
  
  return (
    <>
      <HelmetComponent title={t('SEO Home title') || undefined} descrition={t('SEO Home description') || undefined} />

      <Slider />
      <ThreeD />
      <Events />
      <Multimedia />
    </>
  )
}

export default Home
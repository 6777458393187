import React from 'react'
import { Tabs, Image, Carousel, Row, Col } from 'antd'
import classes from "./css/Multimedia.module.css"
import { useTranslation } from "react-i18next"


const Multimedia: React.FC = () => {

  const { t } = useTranslation()


  // const [position, setPosition] = useState<PositionType[]>(['left']);
  // type PositionType = 'left';

  // const slot = useMemo(() => {
   
  //   return position.reduce(
  //     (acc, direction) => ({ ...acc, [direction]: <span className={classes.multimedia}>{t("ΠΟΛΥΜΕΣΑ")} </span> }),
  //     {},
  //   );
  // }, [position]);


  return (
    <>
      <div className={classes.container}>

        <span className={classes.multimedia}>{t("ΠΟΛΥΜΕΣΑ")} </span>
        <Row justify="center">
          <Col xs={24} sm={24} lg={24} >
            <Tabs
              defaultActiveKey="1"
              size='large'
              centered
              tabBarGutter={30}
              tabBarStyle={{ outlineOffset: 'none' }}
              items={[
                {
                  label: <span className={classes.Tab}> {t("Όλες")} </span>,
                  key: '1',
                  children:

                    <Image.PreviewGroup>
                      <div className={classes.wrapper}>
                        <div className={[classes.box, classes.a].join(" ")}> <Image src='./img/Multimedia/001.jpg' alt='sdfsdf' /></div>
                        <div className={[classes.box, classes.b].join(" ")}> <Image src='./img/Multimedia/02.jpg' alt='sdfsdf' /></div>
                        <div className={[classes.box, classes.c].join(" ")}> <Image src='./img/Multimedia/01.jpg' alt='sdfsdf' /></div>
                        <div className={[classes.box, classes.d].join(" ")}> <Image src='./img/Multimedia/03.jpg' alt='sdfsdf' /></div>
                      </div>
                      <div className={classes.wrapper}>
                        <div className={[classes.box, classes.e].join(" ")}> <Image src='./img/Multimedia/002.jpg' alt='sdfsdf' /></div>
                        <div className={[classes.box, classes.f].join(" ")}> <Image src='./img/Multimedia/022.jpg' alt='sdfsdf' /></div>
                        <div className={[classes.box, classes.g].join(" ")}> <Image src='./img/Multimedia/011.jpg' alt='sdfsdf' /></div>
                        <div className={[classes.box, classes.h].join(" ")}> <Image src='./img/Multimedia/033.jpg' alt='sdfsdf' /></div>
                      </div>
                    </Image.PreviewGroup>,
                },
                {
                  label: <span className={classes.Tab}> {t("Φωτογραφίες")} </span>,
                  key: '2',
                  children:
                    <Image.PreviewGroup>
                      <Row justify="center">
                        <Col xs={24} sm={8} lg={8} span={4}>
                          <Image src='./img/Multimedia/05.jpg' alt='sdfsdf' />

                        </Col>
                        <Col xs={24} sm={8} lg={8} span={4}>
                          <Image src='./img/Multimedia/06.jpg' alt='sdfsdf' />
                        </Col>
                        <Col xs={24} sm={8} lg={8} span={4}>
                          <Image src='./img/Multimedia/07.jpg' alt='sdfsdf' />
                        </Col>
                      </Row>
                    </Image.PreviewGroup>,
                },
                {
                  label: <span className={classes.Tab}> {t("Βίντεο")} </span>,
                  key: '3',
                  children:
                    <div className={classes.biblio}>
                      <h2 className={classes.title}>{t("Βίντεο")}</h2>
                      <div className={classes.p}>

                      </div>
                    </div>
                },
                {
                  label: <span className={classes.Tab}> {t("360 πανοραμική")} </span>,
                  key: '4',
                  children:
                    <div className={classes.biblio}>
                      <h2 className={classes.title}>{t("360 πανοραμική")}</h2>
                      <div className={classes.p}>

                      </div>
                    </div>
                }
              ]}
            />

          </Col>
        </Row>
      </div>
    </>
  )
}

export default Multimedia

import React from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

export const SampleNextArrow = (props: any) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        color: '#055879',
        fontSize: '34px',
        fontWeight: 'bold',
        marginRight: '40px',
        top: "50%",
        width: "50px",
        height: "50px",
        borderRadius: '50%',
        backgroundColor: 'white',
      }}
      onClick={onClick}
    >
      <span style={{marginLeft: '10px', position: 'relative', top: '8px'}}><RightOutlined /></span> 
    </div>
  )
}


export const SamplePrevArrow = (props: any) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        color: '#055879',
        fontSize: '34px',
        fontWeight: 'bold',
        top: "50%",
        left: "1%",
        width: "50px",
        height: "50px",
        borderRadius: '50%',
        backgroundColor: 'white',
        zIndex:'1',
      }}
      onClick={onClick}
    >
      <span style={{marginLeft: '6px', position: 'relative', top: '8px'}}><LeftOutlined /></span>  
    </div>
  )
}
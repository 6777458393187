
import React from "react"
    
const ProductsSvg = () => {
    return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_808" data-name="Rectangle 808" width="33" height="33" transform="translate(-0.425 -0.369)" fill="#063a81"/>
        </clipPath>
      </defs>
      <g id="icon_products" transform="translate(0.425 0.369)">
        <g id="Group_908" data-name="Group 908" clipPath="url(#clip-path)">
          <path id="Path_2592" data-name="Path 2592" d="M11.4,0H3.8A3.8,3.8,0,0,0,0,3.8V11.4a3.8,3.8,0,0,0,3.8,3.8H11.4a3.8,3.8,0,0,0,3.8-3.8V3.8A3.8,3.8,0,0,0,11.4,0m.717,3.8V11.4a.71.71,0,0,1-.717.718H3.8a.7.7,0,0,1-.717-.718V3.8A.7.7,0,0,1,3.8,3.08H11.4a.7.7,0,0,1,.717.716" fill="#063a81"/>
          <path id="Path_2593" data-name="Path 2593" d="M24.993,15.2H32.6a3.8,3.8,0,0,0,3.8-3.8V3.8A3.79,3.79,0,0,0,32.6,0H24.993a3.8,3.8,0,0,0-3.8,3.8V11.4a3.8,3.8,0,0,0,3.8,3.8m-.717-3.8V3.8a.7.7,0,0,1,.717-.717H32.6a.7.7,0,0,1,.718.717V11.4a.71.71,0,0,1-.718.717H24.993a.7.7,0,0,1-.717-.717" transform="translate(-4.061 0)" fill="#063a81"/>
          <path id="Path_2594" data-name="Path 2594" d="M11.4,21.2H3.8a3.8,3.8,0,0,0-3.8,3.8V32.6a3.8,3.8,0,0,0,3.8,3.8H11.4a3.8,3.8,0,0,0,3.8-3.8V24.994a3.8,3.8,0,0,0-3.8-3.8m.717,3.8V32.6a.7.7,0,0,1-.717.718H3.8a.7.7,0,0,1-.717-.718V24.994a.7.7,0,0,1,.717-.717H11.4a.7.7,0,0,1,.717.717" transform="translate(0 -4.061)" fill="#063a81"/>
          <path id="Path_2595" data-name="Path 2595" d="M28.8,21.2a7.6,7.6,0,1,0,7.6,7.6,7.609,7.609,0,0,0-7.6-7.6m4.521,7.6A4.521,4.521,0,1,1,28.8,24.276,4.526,4.526,0,0,1,33.318,28.8" transform="translate(-4.061 -4.061)" fill="#063a81"/>
        </g>
      </g>
    </svg>

    )
}

export default ProductsSvg  
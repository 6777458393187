
import React from "react"


const WebsiteSvg = () => {

    return (
    <svg xmlns="http://www.w3.org/2000/svg"  width="32.399" height="32.336" viewBox="0 0 32.399 32.336">
    <defs>
        <clipPath id="clip-path">
        <rect id="Rectangle_811" data-name="Rectangle 811" width="32.399" height="32.336" fill="#063a81"/>
        </clipPath>
    </defs>
    <g id="icon_site" transform="translate(0)">
        <g id="Group_914" data-name="Group 914" transform="translate(0)" clipPath="url(#clip-path)">
        <path id="Path_2598" data-name="Path 2598" d="M7.5,5.319A1.471,1.471,0,0,0,6.025,6.79V6.8a1.471,1.471,0,1,0,2.943,0V6.79A1.471,1.471,0,0,0,7.5,5.319" transform="translate(-1.154 -1.019)" fill="#063a81" fillRule="evenodd"/>
        <path id="Path_2599" data-name="Path 2599" d="M13.033,5.319A1.471,1.471,0,0,0,11.562,6.79V6.8a1.471,1.471,0,1,0,2.943,0V6.79a1.471,1.471,0,0,0-1.471-1.471" transform="translate(-2.215 -1.019)" fill="#063a81" fillRule="evenodd"/>
        <path id="Path_2600" data-name="Path 2600" d="M18.569,5.319A1.471,1.471,0,0,0,17.1,6.79V6.8a1.471,1.471,0,1,0,2.943,0V6.79a1.471,1.471,0,0,0-1.471-1.471" transform="translate(-3.276 -1.019)" fill="#063a81" fillRule="evenodd"/>
        <path id="Path_2601" data-name="Path 2601" d="M28.038,0H4.36A4.361,4.361,0,0,0,0,4.36V24.42a4.361,4.361,0,0,0,4.36,4.361H13.83a1.471,1.471,0,0,0,0-2.943H4.36A1.418,1.418,0,0,1,2.943,24.42V11.888H29.456V24.42a1.471,1.471,0,0,0,2.943,0V4.36A4.361,4.361,0,0,0,28.038,0M2.943,8.945V4.36A1.417,1.417,0,0,1,4.36,2.943H28.038A1.418,1.418,0,0,1,29.456,4.36V8.945Z" fill="#063a81" fillRule="evenodd"/>
        <path id="Path_2602" data-name="Path 2602" d="M30.321,27.717l1.462-1.707a1.471,1.471,0,0,0-.809-2.4L20.8,21.44a1.472,1.472,0,0,0-1.764,1.649l1.487,10.29a1.47,1.47,0,0,0,2.358.952l1.871-1.455,2.952,3.105a1.471,1.471,0,0,0,2.092.04l3.519-3.42a1.47,1.47,0,0,0-.027-2.135Zm-1.506,5.158h0l-2.846-2.991A1.471,1.471,0,0,0,24,29.737l-.924.718-.822-5.7,5.686,1.215-.774.9a1.472,1.472,0,0,0,.117,2.038l2.872,2.657Z" transform="translate(-3.645 -4.101)" fill="#063a81" fillRule="evenodd"/>
        </g>
    </g>
    </svg>

    )
}

export default WebsiteSvg
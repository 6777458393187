import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { I18nextProvider } from "react-i18next"
import i18n from "./translation"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// Redux
import { Provider } from 'react-redux'
import { store } from './redux/store'

import Header from "./components/Header/Header"
import Footer from "./components/Footer"
import ScrollToTop from '../src/components/ScrollToTop'

// const Home = lazy(() => import('./pages/Home/Home'))
import Home from './pages/Home/Home'
import CommonPage from './pages/CommonPage/CommonPage'
import Events from './pages/Events/Events'
import Contacts from './pages/Contacts/Contacts'
import PageNotFound from './pages/404/404'




import EditProfile from './pages/User/EditProfile'
import CreateDirectory from './pages/User/CreateDirectory'
import CreateAdvertorial from './pages/User/CreateAdvertorial'
import ExportDirectory from './pages/ExportDirectory/ExportDirectory'
import ExportToolkit from './pages/ExportToolkit/ExportToolkit'
import Advertorial from './pages/Advertorial/Advertorial'

import { AuthProvider } from './utils/auth'


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <>
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <AuthProvider>
            <Header />

              <Routes>
                <Route path="/" element={<Home />} />
                <Route index element={<Home />} />
                <Route path="page/:page" element={<CommonPage />} />
                <Route path="events" element={<Events />} />
                <Route path="contacts" element={<Contacts />} />
                <Route path="*" element={<PageNotFound />} />

                
                <Route path="edit_profile" element={<EditProfile />} />
                <Route path="create_directory" element={<CreateDirectory />} />
                <Route path="create_advertorial" element={<CreateAdvertorial />} />

                <Route path="export_directory" element={<ExportDirectory />} />
                <Route path="export_toolkit" element={<ExportToolkit />} />
                <Route path="advertorial" element={<Advertorial />} />
  
                {/* <Route path="*" element={<Navigate to="/" />} /> */}
              </Routes>

            <Footer />
            <ScrollToTop />
          </AuthProvider>
        </I18nextProvider>
      </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  </>
)

import React from "react"


const CompanyName = () => {
    return (
<svg xmlns="http://www.w3.org/2000/svg"  width="36.554" height="32.336" viewBox="0 0 36.554 32.336">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_813" data-name="Rectangle 813" width="36.554" height="32.336" fill="#063a81"/>
    </clipPath>
  </defs>
  <g id="icon_company" transform="translate(0)">
    <g id="Group_918" data-name="Group 918" transform="translate(0)" clipPath="url(#clip-path)">
      <path id="Path_2605" data-name="Path 2605" d="M17.644,0H3.234A3.235,3.235,0,0,0,0,3.235V29.1a3.233,3.233,0,0,0,3.234,3.234h14.41A3.233,3.233,0,0,0,20.877,29.1V3.235A3.235,3.235,0,0,0,17.644,0" transform="translate(0 0)" fill="#063a81"/>
      <path id="Path_2606" data-name="Path 2606" d="M42.55,3.234a3.235,3.235,0,0,1-3.234,3.234H34.53A3.234,3.234,0,1,1,34.53,0h4.787A3.236,3.236,0,0,1,42.55,3.234" transform="translate(-5.996)" fill="#063a81"/>
      <path id="Path_2607" data-name="Path 2607" d="M42.55,19.234a3.233,3.233,0,0,1-3.234,3.234H34.53a3.234,3.234,0,1,1,0-6.467h4.787a3.233,3.233,0,0,1,3.234,3.234" transform="translate(-5.996 -3.065)" fill="#063a81"/>
      <path id="Path_2608" data-name="Path 2608" d="M42.55,35.233a3.233,3.233,0,0,1-3.234,3.234H34.53a3.234,3.234,0,0,1,0-6.468h4.787A3.235,3.235,0,0,1,42.55,35.233Z" transform="translate(-5.996 -6.13)" fill="#063a81"/>
    </g>
  </g>
</svg>
    )
}

export default CompanyName

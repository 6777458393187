import React, {useState, useEffect} from "react"
import { Row, Col, Select } from 'antd'
import { UserOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom"
import classes from "./Header.module.css"
import i18n from "i18next"
import { useTranslation } from "react-i18next";
import sponsor from "../../img/sponsor.png"
import Navbar from './Navbar'
import { useAuth } from "../../utils/auth"
import { useNavigate } from "react-router-dom"
import ModalRegisterForm from "./ModalRegisterForm"
import ModalLoginForm from "./ModalLoginForm"

import { useDispatch } from 'react-redux'
import type { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'
import { useToken } from './useService'
import { Logout }  from '../../redux/mapPage/login'


const Header = () => {

  const { t } = useTranslation()
    

  const showUserData = false;
  const data = "not so secret data";
  // will display false if showUserData is false
  console.log(showUserData && data);






  const auth = useAuth()
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [codeParam, setCodeParam] = useState<any>(null)
  const [isLogged, setIsLogged] = useState<boolean>(false)
  const { Token, refetch } = useToken(codeParam)
  const navigate = useNavigate()

  const url = new URL(`${process.env.REACT_APP_URL_CMS}/oauth/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code&scope=member_scope`);



  // window.onload = function () {
    
  //   // window.location.href = url.href
  //   url.searchParams.set('redirect_uri', `${process.env.REACT_APP_REDIRECT_URI}`);

  //   const codeParam = new URLSearchParams(window.location.search).get('code')
  //   setCodeParam(codeParam)
    
  //   console.log('codeParam', codeParam)


  //   if (codeParam){
  //     window.localStorage.setItem("codeParam", codeParam)
  //     setIsLogged(true)
  //   }

  //   // if (codeParam !== null) {
  //   //   refetch();
  //   //   setIsLogged(true)
  //   // }
  // }

  // const url = new URL(`https://goint-admin.dotsoft.gr/oauth/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=https://goint.dotsoft.gr&response_type=code&scope=member_scope`);



  const dispatch = useDispatch()
  const Logins: any[] = useSelector((state: RootState) => state.Logged.Login)

  url.searchParams.set('redirect_uri', `${process.env.REACT_APP_REDIRECT_URI}`);


  useEffect(() => {
    
    const codeParam = new URLSearchParams(window.location.search).get('code')
    setCodeParam(codeParam)
    
    if (codeParam){
      window.localStorage.setItem("codeParam", codeParam)
      setIsLogged(true)
    }
  }, [])


  useEffect(() => {
    if (codeParam !== null) {
      refetch();
      setIsLogged(true)
    }
  }, [codeParam])




  const handleChange = (value: string) => {
    i18n.changeLanguage(value)
  }


  const Clearmy = async () => {
  
    localStorage.clear();
    (window as Window).location = `${process.env.REACT_APP_URL_CMS}/user/logout`;
    setCodeParam(null);
    dispatch(Logout([]));
    localStorage.clear();
    navigate(`${process.env.REACT_APP_URL_CMS}`)

  }

  
  return (
    <>
      <Row align="middle" justify="end" id="top">
        <Col xs={12} sm={12} md={6} lg={4} >
          <div className={classes.div}>

          <Link to="/">
          <div className={classes.fillLogo}>
              <img src='../img/Slider_logo.jpg' alt="image Slider Logo" />
            </div></Link>

          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={5} >
          <div className={classes.div}>
            <span className={classes.Language}>
              <span style={{ marginLeft: '32px' }}><ArrowDownOutlined /></span>
              <Select
                defaultValue="gr"
                bordered={false}
                showArrow={false}
                size='large'
                style={{ width: '100px', marginTop: '6px', marginLeft: '-4px' }}
                suffixIcon={<UserOutlined />}
                onSelect={handleChange}
                options={[
                  {
                    key: 1,
                    value: 'en',
                    label: (
                      <>
                        <span style={{ marginRight: '12px', fontSize: '14px' }}>EN</span><img width="26" height="26" src="../../img/flags/en.svg" alt="English flag" />
                      </>
                    )
                  },
                  {
                    key: 2,
                    value: 'gr',
                    label: (
                      <>
                        <span style={{ marginRight: '13px', fontSize: '14px' }}>ΕΛ</span><img width="26" height="26" src="../../img/flags/gr.svg" alt="Greek flag" />
                      </>
                    )
                  },
                  {
                    key: 3,
                    value: 'bg',
                    label: (
                      <>
                        <span style={{ marginRight: '12px', fontSize: '14px' }}>BG</span><img width="26" height="26" src="../../img/flags/bg.svg" alt="Bulgarian flag" />
                      </>
                    )
                  }
                ]}
              />
            </span>

            <span className={classes.Login}>
                          
              {/* {ReduxHistory[0] == null && <ModalLoginForm activeIndex={activeIndex} clickModal={() => setActiveIndex(0)} /> } */}

              { localStorage.getItem('access_token') !== null
                ?
                //<span className={classes.LoginLink} onClick={() => { setCodeParam(null);  dispatch(Logout([])); localStorage.clear(); navigate('/'); location.reload() }  }>{t('Αποσύνδεση')}</span>
                <span className={classes.LoginLink} onClick={() => Clearmy() }>{t('Αποσύνδεση')}</span>
                : 
                <>
                  <span className={classes.LoginLink}> <a className={[classes.color, classes.login].join(' ')} href={url.href}>{t('Σύνδεση')}</a> </span>
                  <ModalRegisterForm clickModal={() => setActiveIndex(1)} />
                </>
              }

              <UserOutlined style={{ fontSize: '22px', margin: '10px' }} />
              
            </span>

          </div>
        </Col>
      </Row>

      <div className={[classes.header, classes.flexContainer].join(" ")}>
        <div className={classes.app_logo}></div>
        <Navbar />
        <div className={classes.sponsor_logo__container}>
          <Link to="/">
            <img
              src={sponsor}
              alt="sponsor logo"
              className={classes.logo_normal}
            /></Link>
        </div>
      </div>
    </>
  );
};

export default Header
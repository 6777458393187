import { configureStore } from '@reduxjs/toolkit'
import Login from './mapPage/login'

export const store = configureStore({
  reducer: {
    Logged: Login,
  },
})

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
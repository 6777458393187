
import React from "react"

const SocialMediaSvg = () => {
    return (
    <svg id="icon_social" xmlns="http://www.w3.org/2000/svg" width="35.843" height="32.336" viewBox="0 0 35.843 32.336">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_812" data-name="Rectangle 812" width="35.843" height="32.336" fill="#063a81"/>
        </clipPath>
      </defs>
      <g id="Group_916" data-name="Group 916" clipPath="url(#clip-path)">
        <path id="Path_2603" data-name="Path 2603" d="M31.722,12.476H26.209a16.55,16.55,0,0,0,1.435-9.1,3.842,3.842,0,0,0-.1-.544,3.8,3.8,0,0,0-7.067-.741,3.513,3.513,0,0,0-.287.771c-.581,1.652-.92,3.074-.919,3.082a34.851,34.851,0,0,1-3.532,6.694,6.236,6.236,0,0,0-4.79,6.053v7.433a6.222,6.222,0,0,0,6.216,6.214H31.722a6.225,6.225,0,0,0,6.219-6.213V18.69a6.223,6.223,0,0,0-6.218-6.214" transform="translate(-2.097)" fill="#063a81"/>
        <path id="Path_2604" data-name="Path 2604" d="M4.23,15.432A4.234,4.234,0,0,0,0,19.66v11.4a4.23,4.23,0,1,0,8.459,0V19.66a4.234,4.234,0,0,0-4.23-4.228" transform="translate(0 -2.957)" fill="#063a81"/>
      </g>
    </svg>
    )
}

export default SocialMediaSvg


import React, {useState, useEffect} from 'react'
import { Row, Col, Pagination} from 'antd'
import classes from "./styles.module.css"
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../components/Helmet'
import i18next from 'i18next'

import { useEvents } from '../../hooks/useApi'
import EventsComponent from '../../components/Events'

const Events: React.FC = () => {

  const { t } = useTranslation()

  const { isFetching, Events, refetch } = useEvents()

  const [pageSize, setPageSize] = useState<number>(8)
  const [Poises, setPoises] = useState<any[]>(Events)


  useEffect(() => {
    refetch()
  }, [i18next.language])


  useEffect(() => {
    setPoises(Events)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [Events])



  const onChange = (page: number, pageSize: number) => {

    setPageSize(pageSize)
    setPoises(page * pageSize > Events.length ? Events.slice((page - 1) * pageSize) : Events.slice((page - 1) * pageSize, page * pageSize))
    window.scrollTo({ top: 180, left: 0, behavior: 'smooth' })
  }


  if (isFetching || Events.length==0) { return <div className={classes.container}></div> }
  
  return (
    <>
      <HelmetComponent title={t('Εκδηλώσεις') || undefined} descrition='Εκδηλώσεις' />

      <div className={classes.container}>
        <Row justify="center">
          <Col xs={24} sm={24} lg={24} >
              <h3> {t("Εκδηλώσεις")} </h3>
          </Col>
        </Row>
        <Row justify="center">
          <EventsComponent number={pageSize} events={Poises} />
        </Row>
        <br />
        <br/>
        <Row justify="center">
          <Col xs={{span: 24}} lg={{span: 8}}  >
            <Pagination
              onChange={onChange}
              className={classes.pagination}
              total={Events.length}
              showSizeChanger ={true}
              showTotal={(total, range) => t('Αποτελέσματα') +' '+ `${range[0]} - ${range[1]}`+ ' ' + t('από') +' '+ `${total}`}
              defaultPageSize={8}
              defaultCurrent={1}
              // hideOnSinglePage={true}
              // responsive={true}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Events

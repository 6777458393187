
import React from "react"
import Props from './svgProps'

const ActivitySvg = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36.554" height="32.336" viewBox="0 0 36.554 32.336">
        <defs>
            <clipPath id="clip-path">
            <rect id="Rectangle_807" data-name="Rectangle 807" width="36.554" height="32.336" fill="#063a81"/>
            </clipPath>
        </defs>
        <g id="icon_Activity" transform="translate(0)">
            <g id="Group_906" data-name="Group 906" transform="translate(0)" clipPath="url(#clip-path)">
            <path id="Path_2590" data-name="Path 2590" d="M36.253,4.668a1.4,1.4,0,0,0-1.012-.45H25.4V1.406A1.406,1.406,0,0,0,23.994,0H12.746A1.406,1.406,0,0,0,11.34,1.406V4.217H1.5a1.41,1.41,0,0,0-1.013.436L18.37,21.315Zm-22.1-1.856h8.436V4.217H14.152Zm1.406,11.247a1.4,1.4,0,0,1,1.406-1.406h2.812a1.406,1.406,0,1,1,0,2.812H16.964a1.4,1.4,0,0,1-1.406-1.406" transform="translate(-0.093)" fill="#063a81"/>
            <path id="Path_2591" data-name="Path 2591" d="M19.233,26.175a1.393,1.393,0,0,1-1.912,0L0,10.035V32.852a1.4,1.4,0,0,0,1.406,1.406H35.148a1.4,1.4,0,0,0,1.406-1.406V10.062Z" transform="translate(0 -1.923)" fill="#063a81"/>
            </g>
        </g>
        </svg>

    )
}

export default ActivitySvg
export const AboutProjectData = [{
'gr':{
  id: 1,
  title: 'Το έργο GoInt',
  text:`<p>Το GoInt είναι ένα έργο που χρηματοδοτείται από το πρόγραμμα συνεργασίας INTERREG V-A Ελλάδα - Βουλγαρία 2014-2020 και επικεντρώνεται στην ενίσχυση των διεθνών εξαγωγών των επιχειρήσεων του αγροδιατροφικού τομέα στις γειτονικές επιλέξιμες περιοχές μεταξύ Ελλάδας και Βουλγαρίας, όπως ορίζονται από το Πρόγραμμα.</p>
  <p>Τρεις εταίροι με βασική αποστολή την υποστήριξη των επιχειρήσεων ενώνουν τις δυνάμεις τους για την ανάπτυξη μιας καινοτόμου πλατφόρμας ΤΠΕ, την ανάπτυξη δεξιοτήτων, την υποστήριξη των εξαγωγών σε δύο επιλεγμένες αγορές-στόχους (Βιετνάμ και Ισραήλ), καθώς και τη δημιουργία πρωτοποριακών "Ομάδων Εργασίας Υποστήριξης Εξαγωγών" με στόχο να βοηθήσουν τις επιχειρήσεις να ξεπεράσουν προβλήματα και να βελτιώσουν το επιχειρηματικό κλίμα.</p>
  <p>Απώτερος σκοπός του GoInt είναι η ενίσχυση των παραγόντων που επηρεάζουν τη μακροπρόθεσμη επιχειρηματική επιτυχία της αγροδιατροφικής βιομηχανίας, όπως είναι η ανάπτυξη δεξιοτήτων για τις πωλήσεις, η πρόσβαση σε προγράμματα στήριξης σε ευρωπαϊκό / εθνικό επίπεδο, το ηλεκτρονικό επιχειρείν, το επιθετικό μάρκετινγκ, η πρόσβαση σε ξένες αγορές - κρίσιμοι παράγοντες που εντοπίστηκαν από την έρευνα που πραγματοποίησε το Πρόγραμμα Συνεργασίας το 2015.</p>
  <p>Ως πρώτο βήμα, πάνω από 60 επιχειρήσεις έλαβαν καθοδήγηση μέσω των Ομάδων Εργασίας για την Υποστήριξη των Εξαγωγών από τη Βουλγαρία και την Ελλάδα. Πάνω από 50 εταιρείες απέκτησαν γνώσεις και δικτυώθηκαν μέσω των εστιασμένων εκπαιδεύσεων/ συμβουλευτικής και των συναντήσεων Β2Β στη διάρκεια των αποστολών.</p>
  <p>Όπως είχε αρχικά σχεδιαστεί, το πρόγραμμα ολοκλήρωσε με επιτυχία τη συμμετοχή 5 εταιρειών στην έκθεση AgroMashov στο Ισραήλ στις 5-6 Σεπτεμβρίου 2022 και 2 αποστολών περίπου 20 εταιρειών στις ξένες αγορές στο Ισραήλ και το Βιετνάμ τον Σεπτέμβριο και τον Νοέμβριο.</p>
  <p>Σε εξέλιξη βρίσκονται επίσης λύσεις ΤΠΕ με στόχο την παροχή βοήθειας στις δράσεις των εταιριών που στοχεύουν στην προσέλκυση νέων πελατών στις ξένες αγορές. Αναμένεται ότι περισσότερες από 4.000 εταιρείες στην περιοχή του CBC θα επωφεληθούν από τις επικαιροποιημένες πλατφόρμες TEAS και e-Instrument.</p>
  
  <h3>Προγραμματισμένες εκροές</h3>
  <ul>
  <li style="font-weight:bold; padding-top:8px; padding-bottom:4px;"> a. Υπηρεσίες υποστήριξης των επιχειρήσεων όσον αφορά τον εξαγωγικό προσανατολισμό</li>
  - 10 τεχνικές συνεδριάσεις των Ομάδων Εργασίας για την Υποστήριξη των Εξαγωγών<br/>
  - 4 συναντήσεις Β2Β με πρεσβείες και εμπορικές αποστολές<br/>
  - 2 αποστολές (μία αποστολή σε κάθε μία) στις αγορές-στόχους<br/>
  - Συμμετοχή σε μία έκθεση τροφίμων (ΜμΕ με προσκλήσεις)<br/>
  <li style="font-weight:bold; padding-top:8px; padding-bottom:4px;padding-top: 8px;"> b. Δημιουργία ενός μόνιμου μέσου επιχειρηματικών ΤΠΕ</li>
  - Ανάπτυξη ενός ηλεκτρονικού μέσου ΤΠΕ για την υποστήριξη των επιχειρήσεων<br/>
  - Επικαιροποίηση μιας ηλεκτρονικής πλατφόρμας των Υπηρεσιών Εμπορίου και Οικονομικών Υποθέσεων<br/>
  - Ανάπτυξη μιας νέας ηλεκτρονικής Βάσης Δεδομένων Πρόσβασης στην Αγορά<br/>
  <li style="font-weight:bold; padding-top:8px; padding-bottom:4px;"> c. Ανάπτυξη ανθρώπινων δεξιοτήτων και ικανοτήτων</li>
  - 4 κύκλοι κατάρτισης για τις στοχευόμενες αγορές<br/>
  - Καθοδήγηση ομάδων (10 περιπτώσεις) για την αύξηση των εξαγωγικών ικανοτήτων των ΜμΕ<br/>
  - Εξατομικευμένη συμβουλευτική (18 περιπτώσεις) για την προώθηση και τοποθέτηση προϊόντων<br/>
  </ul>
  
  <h3>Αναμενόμενα αποτελέσματα</h3>
  <ul>
  <li style="font-weight:bold; padding-top:8px; padding-bottom:4px;">a. Παροχή υπηρεσιών υποστήριξης στην αγροδιατροφική βιομηχανία</li>
  - 60 εταιρείες θα λάβουν καθοδήγηση από την ESWG<br/>
  - 50 εταιρείες θα αποκτήσουν δικτύωση μέσω συναντήσεων Β2Β<br/>
  - 20 επιχειρήσεις θα συμμετάσχουν σε αποστολές σε ξένες αγορές<br/>
  - Συμμετοχή 5 επιχειρήσεων στην έκθεση τροφίμων<br/>
  <li style="font-weight:bold; padding-top:8px; padding-bottom:4px;">b. Λύσεις ΤΠΕ</li>
  - 2500 επιχειρήσεις θα επωφεληθούν από το e-Instrument<br/>
  <li style="font-weight:bold; padding-top:8px; padding-bottom:4px;">c. Ανάπτυξη ανθρώπινων δεξιοτήτων και ικανοτήτων</li>
  - 10 εταιρείες BG θα συμμετάσχουν σε συνεδρίες καθοδήγησης<br/>
  - 18 εταιρείες θα λάβουν εξατομικευμένη συμβουλευτική<br/>
  - 100 εμπειρογνώμονες θα λάβουν εκπαιδευτική κατάρτιση<br/>
  </ul>
  `
},
'en':{
  id: 2,
  title: 'About Project',
  text:`<p>GoInt is a Project funded by INTERREG V-A Greece – Bulgaria 2014-2020 co-operation programme focusing on enhancing international exports of AgroFood sector companies in the neighboring eligible areas between Greece and Bulgaria. GoInt is aiming to achieve this with 3 sets of deliverables focused on providing ICT tools and exports perspective to the agro-food SMEs. </p>
  <p>Three partners with constitutional scope to support business join their forces to develop an innovative ICT platform, provide skills development, support exports in two selected target markets (Vietnam & Israel), as well as establish pioneering “Exports Support Working Groups” in order to support businesses tackle their obstacles & ameliorate business climate.</p>
  <p>The ultimate goal of GoInt is to strengthen the factors affecting long-term entrepreneurial success of agro-food industry, such as skills labor for sales, access to EU/ national support, e-business, aggressive marketing, access to foreign markets; critical factors that identified by the survey undertaken by the Cooperation Programme in 2015.</p>
  <p>More than 60 companies will receive guidance through the Export Support Working Groups from Bulgaria and Greece as an initial step. More than 50 companies will gain knowledge and networking via the focused trainings/ consultation and B2B meetings.</p>
  <p>As initially designed, the project has concluded with success the participation of 5 companies in AgroMashov Exhibition in Israel on 5-6 September 2022 and two mission of approx. 20 companies in the foreign markets on Israel and Vietnam on September and November. </p>
  <p>ICT solutions are also in progress with the aim to assist companies in their actions to attract new customers in foreign markets. It is expected that more than 4000 companies in the CBC area will gain from the updated TEAS and e-Instrument platforms.</p>
  
  <h3>Planned Outputs</h3>
  <ul>
  <li style="font-weight:bold; padding-top:8px; padding-bottom:4px;">a. Support services to businesses in terms of export orientation</li>
  - 10 technical meetings of Export Support Working Groups<br/>
  - 4 B2B meetings with embassies and trade missions<br/>
  - 2 missions (one mission in each one) to target markets<br/>
  - Participation in one food fair (SME's by invitations)<br/>
  <li style="font-weight:bold; padding-top:8px; padding-bottom:4px;">b. Establishment of a permanent business ICT instrument</li>
  - Development of one business support ICT e-instrument<br/>
  - Update of one online platform of the Trade and Economic Affairs Services<br/>
  - Development of one new online Market Access Database<br/>
  <li style="font-weight:bold; padding-top:8px; padding-bottom:4px;">c. Develop human skills and capacity</li>
  - 4 Training Cycles for the targeted markets<br/>
  - Mentoring of groups (10 cases) to increase exports capabilities of SMEs<br/>
  - Personalized Counselling (18 cases) on product promotion & placement<br/>
  </ul>

  <h3>Expected Results</h3>
  <ul>
  <li style="font-weight:bold; padding-top:8px; padding-bottom:4px;">a. Provision of support services to the agro-food industry</li>
  - 60 companies to receive guidance from the ESWG<br/>
  - 50 companies to gain networking via B2B meetings<br/>
  - 20 businesses to participate in missions in foreign markets<br/>
  - 5 businesses to participate in the food fair<br/>
  <li style="font-weight:bold; padding-top:8px; padding-bottom:4px;">b. ICT solutions</li>
  - 2500 companies to benefit from the e-Instrument<br/>
  <li style="font-weight:bold; padding-top:8px; padding-bottom:4px;">c. Develop human skills and capacity</li>
  - 10 BG companies to participate in mentoring sessions<br/>
  - 18 companies to receive personalized counselling<br/>
  - 100 experts trained<br/>
  </ul>
  `
},
'bg':{
  id: 3,
  title: 'За проекта',
  text:`<p>GoInt e проект, финансиран от международната програма за трансгранично сътрудничество INTERREG V-A Гърция – България 2014-2020 г., фокусиран върху насърчаване на експортната дейност на компании от хранително-вкусовия сектор в съседните области между Гърция и България. GoInt се стреми да постигне това с три набора от резултати, фокусирани върху предоставянето на ИКТ инструменти и перспектива за износ на МСП в селскостопанската и хранително-вкусовата промишленост.</p>
  <p>Трима партньори с конституционен обхват за подпомагане на бизнеса обединяват силите си, за да разработят иновативна ИКТ платформа, да осигурят развитие на умения, да подкрепят износа в два избрани целеви пазара (Виетнам и Израел), както и да създадат пионерски „Работни групи за подкрепа на износа“, за да подкрепят предприятията в разрешаването на потенциални бизнес казуси, както и да подобрят бизнес климата.</p>
  <p>Крайната цел на проекта е да подсили факторите, оказващи дългосрочно влияние върху предприемаческия успех на агро-хранителната индустрия, като квалифицирана работна ръка, отговаряща за продажбите, достъп до европейска и национална подкрепа, електронен бизнес, агресивен маркетинг, достъп до чужди пазари; критични фактори, идентифицирани от проучването, проведено от Програмата за сътрудничество през 2015 г.</p>
  <p>Като първоначална стъпка, повече от 60 компании ще получат насоки чрез Работната група за подкрепа на износа (Export Support Working Groups) от България и Гърция. </p>
  <p>В основите си проекта залага успешното участие на 5 компании в изложението AgroMashov в Израел на 5-6 септември 2022 г., както и две мисии от приблизително 20 компании на външните пазари в Израел и Виетнам през септември и ноември.</p>
  <p>Разработват се и ИКТ решения с цел подпомагане на компаниите в действията им за привличане на нови клиенти от чужди пазари. Очаква се повече от 4000 компании в областта на транс-граничното сътрудничество да спечелят от актуализираните платформи TEAS и e-Instrument.</p>
  
  <h3>Планирани резултати</h3>
  <ul>
  <li style="font-weight:bold;padding-bottom:4px;">а. Услуги за подпомагане на бизнеса по отношение на експортна ориентация</li>
  - 10 технически срещи на работни групи за подкрепа на износа<br/>
  - 4 B2B срещи с посолства и търговски мисии<br/>
  - 2 мисии за целеви пазари (по една мисия във всеки целеви пазар)<br/>
  - Участие в един хранителен панаир (чрез покани за МСП)<br/>
  <li style="font-weight:bold;padding-bottom:4px;">b. Създаване на постоянен бизнес ИКТ инструмент</li>
  - Разработване на електронен инструмент за ИКТ в подкрепа на бизнеса<br/>
  - Актуализация на една онлайн платформа на службите по търговско-икономически въпроси<br/>
  - Разработване на една нова онлайн база данни за достъп до пазара (Market Access Database)<br/>
  <li style="font-weight:bold;padding-bottom:4px;">с. Развитие на човешките умения и капацитет</li>
  - 4 цикъла на обучение за целевите пазари<br/>
  - Менторинг на групи (10 казуса) за увеличаване на експортните възможности на МСП<br/>
  - Персонализирано консултиране (18 казуса) за промоция и позициониране на продукти<br/>
  </ul>

  <h3>Очаквани резултати </h3>
  <ul>
  <li style="font-weight:bold;padding-bottom:4px;">a. Осигуряване на услуги в подкрепа на агро-хранителната индустрия  </li>
  - 60 компании ще получат насоки от Работните групи в помощ на износа (ESWG)<br/>
  - 50 компании ще увеличат бизнес мрежата си чрез B2B срещи<br/>
  - 20 предприятия ще участват в търговски мисии на чужди пазари <br/>
  - 5 предприятия ще участват в изложение за храни и хранителни продукти <br/>
  <li style="font-weight:bold;padding-bottom:4px;">b. ИКТ решения</li>
  - 2500 компании ще могат да извлекат полза от електронния инструмент (the e-Instrument)<br/>
  <li style="font-weight:bold;padding-bottom:4px;">c. Развитие на човешките умения и капацитет</li>
  - 10 български компании ще участват в менторски сесии <br/>
  - 18 компании ще получат индивидуално консултиране <br/>
  - 100 експерта ще бъдат обучени <br/>
  </ul>
  `
  }}
]

import React, { useState, useEffect } from "react"
import axios from 'axios'
import { Button, Modal, Form, Input, notification, Divider, Col, Checkbox, message, AutoComplete } from 'antd'
import classes from './Header.module.css'
import { useTranslation } from "react-i18next"
import { useUserRegister } from '../../hooks/useUser'
import { UserOutlined, MailOutlined, LockOutlined, ContactsOutlined } from '@ant-design/icons'
import { useGetTitles } from '../../hooks/useApi'


interface Props{
    clickModal: (one : number) => void,
}

const ModalRegisterForm: React.FC<Props> = ({clickModal}) => {

    const { t } = useTranslation()
    const [form] = Form.useForm()
    const [api, contextHolder2] = notification.useNotification()
    const [messageApi, contextHolderMes2] = message.useMessage();
    const [username, setUsername] = useState<string>('') 
    const [email, setEmail] = useState<string>('') 
    const [password, setPassword] = useState<string>('')
    const [password2, setPassword2] = useState<string>('')
    const [checkBox, setCheckBox] = useState<boolean>(false)
    const [stringTemp, setStringTemp] = useState<string>("")

    const { isFetching, GetTitles, GetTitlesCheck } = useGetTitles()

    // console.log('GetTitles', GetTitles)

    const error = (text:string) => {
        messageApi.open({
            type: 'error',
            content: text,
        });
    };

    const openNotification = (title: string, text:string) => {
        api.info({
        message: title,
        description: text,
        placement: 'top',
        });
    }


    const closeModal = () => {
        Modal.destroyAll()
        clickModal(1)
    }

    const showModal2 = () => {
        Modal.info({
            centered: true,
            closable: true,
           
            title: t('Δημιουργία Λογαριασμού'),
            width: 520,
            content: (
                <>
                    <br />
                    <Form
                        layout="vertical"
                        autoComplete="off"
                        wrapperCol={{ span: 22 }}
                        form={form}
                        onFinish={onFinish}
                        style={{ maxWidth: 500 }}
                    >
                        <Form.Item label={t('Επωνυμία εταιρείας')} name="company" hasFeedback rules={[{ required: true, message: 'Πληκτρολόγησε την Επωνυμία εταιρείας'  }]}>
                           
                        <AutoComplete
                            dropdownMatchSelectWidth={252}
                            className={classes.input}
                            options={GetTitles.length >=2 ? GetTitles : []}
                            onSelect={onSelect}
                            onChange={onChange}
                            filterOption={(inputValue, option) =>
                            inputValue.length >= 2 &&  
                            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 }
                            > 
                                                
                            <Input prefix={<ContactsOutlined className={classes.colorGrey} />} onChange={(e) => setStringTemp(e.target.value)} placeholder={t('Πληκτρολόγησε την Επωνυμία εταιρείας εδώ') || undefined} />
                                        
                            {/* <Input
                                placeholder={t("Εισάγετε ένα όρο αναζήτησης") || ''}
                                prefix={<SearchOutlined />}
                                className={classes.inputMain}
                                onChange={(e) => setStringTemp(e.target.value)}
                                onKeyPress={ handleKeypress }
                            /> */}
                        </AutoComplete>
                           
                           
                           
                            {/* <Input prefix={<ContactsOutlined className={classes.colorGrey} />} placeholder={t('Πληκτρολόγησε την Επωνυμία εταιρείας εδώ') || undefined} />
                         */}
                        
                        </Form.Item>
                        {/* <Col span={22}> <Divider /></Col> */}
                        
                        <Form.Item label="Email" name="email" hasFeedback rules={[{ type:'email', required: true, message: 'Πληκτρολόγησε το Email σου' }]}>
                            <Input prefix={<MailOutlined  className={classes.colorGrey} />} placeholder={t('Πληκτρολόγησε το Email σου εδώ') || undefined } />
                        </Form.Item>
                        {/* <Col span={22}> <Divider /></Col> */}

                        <Form.Item label={t('Κωδικός')} name="password" hasFeedback rules={[{ required: true, message: 'Please input your password!' }]}>
                            <Input.Password prefix={<LockOutlined className={classes.colorGrey} />} placeholder={t('Πληκτρολόγησε τον κωδικό σου εδώ') || undefined } />
                        </Form.Item>
                        {/* <Col span={22}> <Divider /></Col> */}

                        <Form.Item label={t('Επιβεβαίωση Κωδικού')} name="password2" hasFeedback rules={[{ required: true, message: 'Please input your password!' }]}>
                            <Input.Password prefix={<LockOutlined className={classes.colorGrey} />} placeholder={t('Πληκτρολόγησε τον κωδικό σου εδώ') || undefined }/>
                        </Form.Item>
                        {/* <Col span={22}> <Divider /></Col> */}


                        {/* <Form.Item name="remember" valuePropName="checked" wrapperCol={{ span: 22 }}>      
                            <div className={classes.checkBox}>
                                <Checkbox></Checkbox>&nbsp;&nbsp;
                                {t('Συμφωνώ με τους')} <a href= '/' className={classes.underline}>{t('Όρους Χρήσης')}</a>{t('και την')}<a href='/' className={classes.underline} >{t('Πολιτική Απορρήτου')}</a>
                            </div>
                        </Form.Item> */}

                        <Form.Item wrapperCol={{ offset: 2, span: 22 }}>
                            <Button htmlType="submit" className={classes.signUpBtn}>{t('Εγγραφή')}</Button>
                            <Button htmlType="button" className={classes.signUpBtn} onClick={onReset}>{ t('Επαναφορά')}</Button>
                            {/* <Button type="link" htmlType="button" onClick={onFill} >Συμπληρώστε τη φόρμα Sergios...</Button> */}
                        </Form.Item>

                        {/* <div className={classes.textBold} >
                            {t('Έχεις ήδη εγγραφεί;')} <span className={classes.textBoldSignIn} onClick={()=> closeModal()}>{t('Συνδέσου στο λογαριασμό σου')}</span> 
                        </div> */}
                    </Form>
                </>
            ),
            onOk() { },
        });
    };



    useEffect(() => {
        if (username !== '')
            openNotification(t('Δημιουργία Λογαριασμού'), t('Ελέγξτε το email σας, για να ολοκληρώσετε την εγγραφή σας! Ελέγξτε τον φάκελο SPAM, εάν δεν μπορείτε να τον βρείτε στα εισερχόμενά σας!'))
    }, [username])



    
    const onFinish = (values: any) => {

        // console.log(values)

        let check=0

        GetTitlesCheck.includes(values.company)
            
            GetTitles.map((title: any) => {
                if (title.value == values.company)
                 check = title.id   
            })



        // if (values.remember == true) {
            if (values.password === values.password2) {

                setUsername(values.email)

                let exist = {
                    "email": values.email,
                    "password": values.password,
                    "export_directory": check   
                }
        

                let notExist = {
                    "email": values.email,
                    "password": values.password,
                    "export_directory_title": values.company 
                }
                
                axios({
                    method: 'post',
                    url: `https://goint-admin.dotsoft.gr/api/users/register`,
                    data: check !== 0 ? exist : notExist,
                    timeout: 1000,
                    headers: { "Content-Type": "application/json" }
        
                }).then(({ data }) => {
        
                    console.log(data)
        
                  }).catch(err => console.log("err", err))

                setCheckBox(values.remember)
                form.resetFields()
                Modal.destroyAll()
            }
            else 
                error(t('Οι κωδικοί δεν ταιριάζουν'))
        // }
        // else
        //     error(t('Παρακαλώ αποδεχτείτε τους Όρους χρήσης και την Πολιτική Απορρήτου')) 
    }


    const onReset = () => {
        form.resetFields();
    }

    const onFill = () => {
        form.setFieldsValue({ username:'sergios', email: 'ser11@mail.ru', password: '12345678', password2: '12345678' });
    }

    const onSelect = (data: string) => { setStringTemp(data); }
    const onChange = (data: string) => { setStringTemp(data); }
    
    return (
        <>
            <span className={classes.LoginLink} onClick={showModal2} > {t('Εγγραφείτε')}</span> 
        </>
    )
}
export default ModalRegisterForm


import React, {useState} from 'react'
import { Row, Col, Modal, Typography} from 'antd'
import classes from "./styles.module.css"
import { useTranslation } from "react-i18next"
import { EventsProps } from '../../services/props'

const { Title } = Typography


interface Props{
  number: number
  events: {
    id: number 
    date: string
    event: string
    city: string
    modalText: string
    shortDescription: string
    info: string
    languages: string
  }[]
}



const EventsComponent: React.FC<Props> = ({ number, events }:Props) => {

  const { t } = useTranslation()

  const Event = ({ date, event, city, modalText, shortDescription, info, languages }: EventsProps) => {

    const [hover, setHover] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const showModal = () => setOpen(true)
    const hideModal = () => setOpen(false)

    return (
      <>
        <div
          className={classes.boxContainer}
          onMouseEnter={() => { setHover(true) }}
          onMouseLeave={() => { setHover(false) }} >
          <div className={classes.wrapper}>
            <div className={[classes.box, classes.a].join(" ")} style={hover ? { color: 'white' } : {}}>{t('ΑΝΑΚΟΙΝΩΣΕΙΣ')}</div>
            <div className={[classes.box, classes.b].join(" ")} style={hover ? { color: 'white' } : {}}>{date}</div>
            <div className={[classes.box, classes.c].join(" ")} style={hover ? { color: 'white' } : {}}>{event}</div>
            <div className={[classes.box, classes.d].join(" ")} style={hover ? { color: 'white' } : {}}>{city}</div>
            {modalText !== '' && 
            <div className={[classes.box, classes.e].join(" ")} style={hover ? { color: 'white' } : {}}>
              <span className={classes.readMoreContainerSpan} onClick={showModal} >
                {t('Διαβάστε περισσότερα')}
              </span>
            </div>
            }
          </div>
        </div>
        <Modal
          title={<><Title level={3}>{event}</Title></>}
          centered
          width={600}
          footer={false}
          open={open}
          onOk={hideModal}
          onCancel={hideModal}
        >
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <div className={classes.ModalText} dangerouslySetInnerHTML={{ __html: modalText }} ></div>
              {/* {shortDescription.length >= 1 &&
                <>
                  <br />
                  <strong>Description:</strong><div className={classes.ModalText} dangerouslySetInnerHTML={{ __html: shortDescription }} ></div>
                </>
              } */}
              {info.length >= 1 &&
                <>
                  <br />Info:
                  <div className={classes.ModalText} dangerouslySetInnerHTML={{ __html: info }} ></div>
                </>
              }
              {languages.length >= 1 &&
                <>
                  <br />
                  <div>Languages: {languages} </div>
                </>
              }
            </Col>
          </Row>
        </Modal>
      </>
    )
  }
 
  return (
    
   <>
      {events.slice(0, number).map((event: EventsProps) => {
        return (
          <Col xs={24} sm={24} md={12} lg={6} key={event.id}>
            <Event
              id={event.id}
              date={event.date}
              event={event.event}
              city={event.city}
              modalText={event.modalText}
              shortDescription={event.shortDescription}
              info={event.info}
              languages={event.languages}
            />
          </Col>
        )
      })}
    </>
  )
}

export default EventsComponent

import axios from 'axios'

const API_URL = `${process.env.REACT_APP_URL_CMS}/api`
const X_API_KEY = 'vPYFRMbH3Wrro0p5CsKvizFe3asJJcLg'

export const axiosRegisterOrSignUp = {

  async postRegister(email: string, password: string) {

    const data = { email: email, password: password };
    const headers = { 
      'API-KEY': 'vPYFRMbH3Wrro0p5CsKvizFe3asJJcLg',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
    };
    const response: any = await axios.post(`${API_URL}/users/register`, data, { headers } )

    return response.status

  },
  

  async getToken(code: string) {

    const data = {
      client_id: `${process.env.REACT_APP_CLIENT_ID}`,
      client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
      code: code,
      redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}`,
      scope: `${process.env.REACT_APP_SCOPE}`,
      grant_type: `${process.env.REACT_APP_GRANT_TYPE}`,
    };

    const headers = {
      'API-KEY': 'vPYFRMbH3Wrro0p5CsKvizFe3asJJcLg',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      'content-type': 'application/x-www-form-urlencoded'
    };

    const response = await axios.post(`${process.env.REACT_APP_URL_CMS}/oauth/token`, data, { headers } )

    return response.data
  },



  async refreshToken() {

    fetch(`${process.env.REACT_APP_URL_CMS}/oauth/token`, {
      method: 'POST',
      headers: {
        'API-KEY': 'vPYFRMbH3Wrro0p5CsKvizFe3asJJcLg',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        'content-type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        grant_type: "refresh_token",
        refresh_token: `${window.localStorage.getItem("refresh_token")}`,
        client_id: `${process.env.REACT_APP_CLIENT_ID}`,
        client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
      })
    }).then((response) => {
        return response.json();
    }).then((data) => {

      if (data.refresh_token != undefined) {
        window.localStorage.setItem("refresh_token", data.refresh_token)
        window.localStorage.setItem("access_token", data.access_token)
        console.log("Access_token From GSIS: ", window.localStorage.getItem("access_token"))
        console.log("Refresh_token From GSIS: ", window.localStorage.getItem("refresh_token"))
      }
    })

    return null
  }



}








// const response = await fetch(`${API_URL}/users/register`, {
//   method: 'POST',
//   headers: {
//     'API-KEY': 'vPYFRMbH3Wrro0p5CsKvizFe3asJJcLg',
//     "Access-Control-Allow-Origin": "*",
//     "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
//   },
//   body: JSON.stringify({ email: email, password: password })
// })

// const data = await response.json();
  
// return data


// .........................................................................................................




// const response = await fetch(`https://cms.alter-trip.eu/oauth/token`, {
//   method: 'POST',
//   headers: {
//     'API-KEY': 'vPYFRMbH3Wrro0p5CsKvizFe3asJJcLg',
//     "Access-Control-Allow-Origin": "*",
//     "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
//   },
//   body: new URLSearchParams({
//     client_id: `${process.env.REACT_APP_CLIENT_ID}`,
//     client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
//     code: code,
//     redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}`,
//     scope:  `${process.env.REACT_APP_SCOPE}`,
//     grant_type:  `${process.env.REACT_APP_GRANT_TYPE}`,
//   })
// })

// const data = await response.json();
// return data
import axios from 'axios'
import ky from 'ky'
import { API_URL, HeaderForUser } from './configuration'


export const user = {

  async loginUser(email: string, password: string) {


    // const { data } = await axios.post(`https://chania.mitos.cityguideplatform.com/api/en/login?email=sergios@dotsoft.gr&password=12345678`, {
    //     headers: {
    //       'X-Authorization': 'hfG7u8C4UNMw17E6Jyzq'
    //   }
    // })
    
    const response = await fetch(`https://chania.mitos.cityguideplatform.com/api/en/login?email=${email}&password=${password}`, {
          method: 'POST',
          headers: new Headers({
              'X-Authorization': 'hfG7u8C4UNMw17E6Jyzq'
          })
          
    })

   const data = await response.json();
         
    return data
  },
  
  async registerUser(username: string, email: string, password: string) {
    const data: any = await axios.post(`${API_URL}/users/register`,
    {
      "email": email,
      "password": password,
      "export_directory_title": "DOTSOFT"
    },
    { headers: HeaderForUser })
    console.log(data)
    
    return data.data.json()
  },


  async getUserProfile(token:string) {

    const { data }: any = await axios.get(`${API_URL}/user/profile`, {
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    })

    return data[0]
  },

}


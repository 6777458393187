import React, { useState, useEffect }  from 'react'
import { Row, Col, Modal, Typography } from 'antd'
import classes from "./css/ThreeData.module.css"

import { useTranslation } from "react-i18next"
import { ThreeDataGr, ThreeDataEn, ThreeDataBg } from '../ThreeD'
import i18next from 'i18next'

interface Events{
  id?: number
  bgColor: string
  image: string
  title: string
  body: string
  web: string
  modalText: string
  modalContact: string
}

const { Title } = Typography
  
const ThreeD: React.FC = () => {

  const { t } = useTranslation()

  const [data, setData] = useState<Events[]>(ThreeDataGr)

  useEffect(() => {
    // console.log('my')
    switch (i18next.language) {
      case 'en': setData(ThreeDataEn); break;
      case 'gr': setData(ThreeDataGr); break;
      case 'bg': setData(ThreeDataBg); break;
    }

  }, [i18next.language])

  const Event = ({  bgColor, image, title, body, web, modalText, modalContact }:Events) => {
   
    const [open, setOpen] = useState<boolean>(false);
    const showModal = () => setOpen(true)
    const hideModal = () => setOpen(false)

    return (
      <>
        <div className={classes.boxContainer}>
          <div className={classes.wrapper}>
            <div className={[classes.box, classes.a].join(" ")} ><img src={image} alt={title} /></div>
            <div className={[classes.box, classes.b].join(" ")} >{title}</div>
            <div className={[classes.box, classes.c].join(" ")} style={{ backgroundColor: `${bgColor}` }}>{body}</div>
            <div className={[classes.box, classes.d].join(" ")} style={{ backgroundColor: `${bgColor}` }}><div dangerouslySetInnerHTML={{ __html: web }} ></div></div>

            <div className={[classes.readMoreContainer, classes.e].join(" ")}>
              <span className={classes.readMoreContainerSpan} onClick={showModal} >
                {t('Διαβάστε περισσότερα')}
              </span>
            </div>

          </div>
        </div>
        
        <Modal
          title={<><Title level={3}>{title}</Title></>}
          centered
          width={1000}
          footer={false}
          open={open}
          onOk={hideModal}
          onCancel={hideModal}
        >
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              
              <div className={classes.ModalText} dangerouslySetInnerHTML={{ __html: modalText }} ></div>
              <hr/>
              <div className={classes.ModalContact} dangerouslySetInnerHTML={{ __html: modalContact }} ></div>
              {/* <div className={classes.ModalText}>{body}</div> */}
            </Col>
          </Row>
        </Modal>  
    </>
    )
 }
 
  return (
    <>
    <div className={classes.backgroundGrey}>
      <div className={classes.container}>
       
        <Row justify="center">

          {data.map((data: Events) => {
            return (
              <div style={{ textAlign: 'center' }} key={data.id}>
              <Col xs={24} sm={8} md={8} lg={8}  >
                
                <Event
                  bgColor={data.bgColor}
                  image={data.image}
                  title={data.title}
                  body={data.body}
                  web={data.web}
                  modalText={data.modalText}
                  modalContact={data.modalContact}
                />
              </Col></div>
            )
          })}

        </Row>
      </div>
    </div>
    </>
  )
}

export default ThreeD


import React from "react"
import Props from './svgProps'

const ContactPersonSVG = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg"  width="33" height="32" viewBox="0 0 33 32">
        <defs>
            <clipPath id="clip-path">
            <rect id="Rectangle_806" data-name="Rectangle 806" width="33" height="32" transform="translate(-0.425 0.449)" fill="#063a81"/>
            </clipPath>
        </defs>
        <g id="icon_contact" transform="translate(0.425 -0.449)">
            <g id="Group_904" data-name="Group 904" clipPath="url(#clip-path)">
            <path id="Path_2587" data-name="Path 2587" d="M22.8,0H9.535A9.553,9.553,0,0,0,0,9.535V22.8H0a9.551,9.551,0,0,0,9.535,9.535H22.8A9.55,9.55,0,0,0,32.336,22.8V9.536A9.55,9.55,0,0,0,22.8,0Zm7.048,22.8A7.063,7.063,0,0,1,22.8,29.849H9.535A7.065,7.065,0,0,1,2.487,22.8V9.535A7.066,7.066,0,0,1,9.535,2.487H22.8A7.066,7.066,0,0,1,29.85,9.535Z" fill="#063a81"/>
            <path id="Path_2588" data-name="Path 2588" d="M18.526,18.591a6.218,6.218,0,1,0-4.4-1.821,6.219,6.219,0,0,0,4.4,1.821m0-9.95a3.73,3.73,0,1,1-2.639,1.093,3.731,3.731,0,0,1,2.639-1.093" transform="translate(-2.358 -1.179)" fill="#063a81"/>
            <path id="Path_2589" data-name="Path 2589" d="M21.057,24.615H14.424A6.218,6.218,0,0,0,8.2,30.833a1.244,1.244,0,1,0,2.487,0A3.732,3.732,0,0,1,14.424,27.1h6.633a3.732,3.732,0,0,1,3.731,3.731,1.244,1.244,0,1,0,2.487,0,6.218,6.218,0,0,0-6.218-6.218" transform="translate(-1.572 -4.716)" fill="#063a81"/>
            </g>
        </g>
        </svg>

    )
}

export default ContactPersonSVG
import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
// import XHR from "i18next-xhr-backend";

import translationEn from "./locales/en/translation.json"
import translationGr from "./locales/gr/translation.json"
import translationBg from "./locales/bg/translation.json"

i18n
  // .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: "gr",
    fallbackLng: "gr",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },

    resources: {
      en: {
        translations: translationEn,
      },
      gr: {
        translations: translationGr,
      },
      bg: {
        translations: translationBg,
      }
    },
    detection: {
      order: ["path", "cookie"],
      caches: ["cookie"],
      cookieMinutes: 7 * 24 * 60 * 60 * 1000, //7 days
    },
    react: { useSuspense: false },
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n
export const API_URL = 'https://goint-admin.dotsoft.gr/api'











export const Header = {
  'X-API-KEY': 'hfG7u8C4UNMw17E6Jyzq',
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
}

export const HeaderForUser = { 'content-type':	'application/json' }
export const API_KEY = '1Wt8DjIQ90GuPuovuI1O8M9Axe7OhZA4'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Row, Col, Button, Form, Input, Modal, Upload, message, Select} from 'antd'
import classes from "./styles.module.css"

import i18next from 'i18next'
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../components/Helmet'


import { PlusOutlined } from '@ant-design/icons';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';

import type { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'


const CreateDirectory: React.FC = () => {

  const { t } = useTranslation()

  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [save, setSave]=useState<boolean>(false)
  const [messageApi, successMessage] = message.useMessage()
  const [messageApiError, errorMessage] = message.useMessage()
  const [form] = Form.useForm()
  const Login: any[] = useSelector((state: RootState) => state.Logged.Login)
  let image_Array: any = []


  // console.log(Login)

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Saved',
      className: 'custom-class',
      style: {
        marginTop: '20vh',
      },
    });
  };

  const wrong = () => {
    messageApiError.open({
      type: 'error',
      content: 'Something wrong',
      className: 'custom-class',
      style: {
        marginTop: '20vh',
      },
    });
  };

  useEffect(() => {

  }, [])

  const layout = { labelCol: {  sm:{ span:12 }, md:{ span:12 }, lg:{ span:8 }   }, wrapperCol: {  sm:{ span:12 }, md:{ span:12 }, lg:{ span:16 } } }

  const validateMessages = {
    required: '${label} is required!',
    image: 'Image is Required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }

  const props: UploadProps = {

    onRemove: (file) => {

      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },

    beforeUpload: (file) => {
      console.log('image');
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (!isLt1M) {
        message.error('File must smaller than 1MB!');

        // const index = fileListPdf.indexOf(file);
        // const newFileList = fileListPdf.slice();
        // newFileList.splice(index, 0);
        // setFileListPdf(newFileList);
        // console.log('fileList',fileListPdf )
        return
      }
      setFileList([...fileList, file]);

      return false;
    },
    // fileListPdf,
  }



  const onFinish = async (values: any) => {

   // console.log(values)

    if (values.ArrayImages !== undefined) {

      setSave(true)

      if (values.ArrayImages !== undefined) {

        values.ArrayImages.fileList.map((item: any, index: number) => {

          const fileReader = new FileReader()
          fileReader.readAsBinaryString(item.originFileObj)
          const reader = new FileReader()
          reader.readAsArrayBuffer(item.originFileObj)

          reader.onload = () => {

            const image = reader.result;

            axios({
              method: 'post',
              url: `https://goint-admin.dotsoft.gr/api/node/export_directory/field_directory_logo`, //API url
              data: image,
              timeout: 2000,
              headers: {
                "Content-Type": "application/octet-stream",
                "Content-Disposition": `file; filename=\"${Math.floor(Math.random() * 100000000)}_${item.name}\"`,
                "X-Requested-With": "XMLHttpRequest",
                "Authorization": `Bearer ${Login[0]}`
              
           
              }

            }).then(({ data }) => {

              console.log(data.data.id),

                image_Array.push({
                  "type": "file--file",
                  "id": data.data.id,
                  "meta": {
                    "alt": "image Alt",
                    "title": "image Title",
                    "width": null,
                    "height": null
                  }
                })
            }).catch(err => console.log("err", err))
          }
        })
      }


      setTimeout(() => {

        const url = 'https://goint-admin.dotsoft.gr/api/node/export_directory'
        const headers = {
          "Content-type": "application/vnd.api+json",
          "Accept": "application/vnd.api+json",
          "Authorization": `Bearer ${Login[0]}`
        
        }

        const data = `
      {
          "data": {
            "type": "node--export_directory",
            "attributes": {
              "title": "${values.title !== undefined ? values.title : 'Title'}",
              "field_export_directory_description": {
                "value": "${values.description !== undefined ? values.description : 'Description'}",
                "format": "plain_text"
              },
              "field_directory_activity":"${values.activity !== undefined ? values.activity : ''}",
              "field_directory_contact_person":"${values.contactPerson !== undefined ? values.contactPerson : ''}",
              "field_directory_email":"${values.email !== undefined ? values.email : ''}",
              "field_directory_products":["some product", "another product"],
              "field_directory_social_media":[
                {"title":"Facebook","uri":"${values.facebook !== undefined ? values.facebook : ''}"},
                {"title":"Instagram","uri":"${values.instagram !== undefined ? values.instagram : ''}"},
                {"title":"linkedIn","uri":"${values.linkedIn !== undefined ? values.linkedIn : ''}"},
                {"title":"YouTube","uri":"${values.youtube !== undefined ? values.youtube : ''}"}
              ],
              "field_directory_telephone":"${values.phone !== undefined ? values.phone : ''}",
              "field_directory_website":{
                "uri":"${values.web !== undefined ? values.web : ''}",
                "title":"official drupal site"
              },
              "field_address": {
                  "country_code":"${values.countryCode !== undefined ? values.countryCode : ''}",
                  "address_line1":"${values.address !== undefined ? values.address : 'Egnatia'}",
                  "locality":"${values.city !== undefined ? values.city : 'Thessaloniki'}",
                  "postal_code":"${values.postCode !== undefined ? values.postCode : ''}"
                }
              },
            "relationships": {
              "field_directory_logo": {
                "data": {
                  "type": "file--file",
                  "id": "${image_Array[0].id}",
                  "meta": {
                    "alt": "Directory Logo Image",
                    "title": "Directory Logo Image",
                    "width": null,
                    "height": null
                  }
                }
              }
            }
          }
        }`;


        axios({
          method: 'post',
          url: url,
          data: data,
          // timeout: 200000,
          headers: headers
        }).then((response) => [
          setSave(false),
          success(),
          form.resetFields(),
          window.scrollTo(0, 0)])
          .catch(err => [
            console.log("err", err),
            setSave(false),
            wrong(),
            form.resetFields(),
            window.scrollTo(0, 0)]);

      }, 3000)

    }// end if
  }
    

  const onReset = () => {
    form.resetFields();
  }

  form.setFieldsValue({
    countryCode: 'GR'
  })

  return (
    <>
      <span>
        {successMessage}
      </span>
      <span> 
        {errorMessage}
      </span>
      <HelmetComponent title='Create a Directory listing' descrition='Create a Directory listing' />
        <div className={classes.container}>
          <Row justify="center">
            <Col  xs={{span:24}} lg={{span : 20}}>
              <div className={classes.container}>

              <div className={classes.titleMain}><span>{t('Καταχώρηση σε Directory')}</span></div>

              <Form
                {...layout}
                form={form}
                name="nest-messages"
                onFinish={onFinish}
                style={{ textAlign: 'right' }}
                validateMessages={validateMessages}
              >
                
                  <Form.Item name='ArrayImages'
                    className={classes.item}
                    label={<label className={classes.itemName}>{t('Λογότυπο')}</label>}
                    rules={[{ required: true, message: 'Please select Image' }]}>
                  {/* <Input className={classes.input} /> */}
                   
                    <Upload
                      listType="picture-card"
                      {...props}
                      // fileList={fileList}
                      accept="image/png, image/jpeg, image/jpg"
                      maxCount={1}
                    >
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    </Upload>
                  </Form.Item>
                

                  <Form.Item name='countryCode'
                    className={classes.item}
                    label={<label className={classes.itemName}>{t('Χώρα')}</label>}
                    rules={[{ required: true }]}>
                    <Select size={'large'} style={{ width: 200 }} defaultValue="GR">
                      <Select.Option value="AF">Afghanistan</Select.Option>
                      <Select.Option value="AL">Albania</Select.Option>
                      <Select.Option value="DZ">Algeria</Select.Option>
                      <Select.Option value="AS">American Samoa</Select.Option>
                      <Select.Option value="AD">Andorra</Select.Option>
                      <Select.Option value="AO">Angola</Select.Option>
                      <Select.Option value="AI">Anguilla</Select.Option>
                      <Select.Option value="AQ">Antarctica</Select.Option>
                      <Select.Option value="AG">Antigua & Barbuda</Select.Option>
                      <Select.Option value="AR">Argentina</Select.Option>
                      <Select.Option value="AM">Armenia</Select.Option>
                      <Select.Option value="AW">Aruba</Select.Option>
                      <Select.Option value="AC">Ascension Island</Select.Option>
                      <Select.Option value="AU">Australia</Select.Option>
                      <Select.Option value="AT">Austria</Select.Option>
                      <Select.Option value="AZ">Azerbaijan</Select.Option>
                      <Select.Option value="BS">Bahamas</Select.Option>
                      <Select.Option value="BH">Bahrain</Select.Option>
                      <Select.Option value="BD">Bangladesh</Select.Option>
                      <Select.Option value="BB">Barbados</Select.Option>
                      <Select.Option value="BY">Belarus</Select.Option>
                      <Select.Option value="BE">Belgium</Select.Option>
                      <Select.Option value="BZ">Belize</Select.Option>
                      <Select.Option value="BJ">Benin</Select.Option>
                      <Select.Option value="BM">Bermuda</Select.Option>
                      <Select.Option value="BT">Bhutan</Select.Option>
                      <Select.Option value="BO">Bolivia</Select.Option>
                      <Select.Option value="BA">Bosnia & Herzegovina</Select.Option>
                      <Select.Option value="BW">Botswana</Select.Option>
                      <Select.Option value="BV">Bouvet Island</Select.Option>
                      <Select.Option value="BR">Brazil</Select.Option>
                      <Select.Option value="IO">British Indian Ocean Territory</Select.Option>
                      <Select.Option value="VG">British Virgin Islands</Select.Option>
                      <Select.Option value="BN">Brunei</Select.Option>
                      <Select.Option value="BG">Bulgaria</Select.Option>
                      <Select.Option value="BF">Burkina Faso</Select.Option>
                      <Select.Option value="BI">Burundi</Select.Option>
                      <Select.Option value="KH">Cambodia</Select.Option>
                      <Select.Option value="CM">Cameroon</Select.Option>
                      <Select.Option value="CA">Canada</Select.Option>
                      <Select.Option value="IC">Canary Islands</Select.Option>
                      <Select.Option value="CV">Cape Verde</Select.Option>
                      <Select.Option value="BQ">Caribbean Netherlands</Select.Option>
                      <Select.Option value="KY">Cayman Islands</Select.Option>
                      <Select.Option value="CF">Central African Republic</Select.Option>
                      <Select.Option value="EA">Ceuta & Melilla</Select.Option>
                      <Select.Option value="TD">Chad</Select.Option>
                      <Select.Option value="CL">Chile</Select.Option>
                      <Select.Option value="CN">China</Select.Option>
                      <Select.Option value="CX">Christmas Island</Select.Option>
                      <Select.Option value="CP">Clipperton Island</Select.Option>
                      <Select.Option value="CC">Cocos (Keeling) Islands</Select.Option>
                      <Select.Option value="CO">Colombia</Select.Option>
                      <Select.Option value="KM">Comoros</Select.Option>
                      <Select.Option value="CG">Congo - Brazzaville</Select.Option>
                      <Select.Option value="CD">Congo - Kinshasa</Select.Option>
                      <Select.Option value="CK">Cook Islands</Select.Option>
                      <Select.Option value="CR">Costa Rica</Select.Option>
                      <Select.Option value="HR">Croatia</Select.Option>
                      <Select.Option value="CU">Cuba</Select.Option>
                      <Select.Option value="CW">Curaçao</Select.Option>
                      <Select.Option value="CY">Cyprus</Select.Option>
                      <Select.Option value="CZ">Czechia</Select.Option>
                      <Select.Option value="CI">Côte d’Ivoire</Select.Option>
                      <Select.Option value="DK">Denmark</Select.Option>
                      <Select.Option value="DG">Diego Garcia</Select.Option>
                      <Select.Option value="DJ">Djibouti</Select.Option>
                      <Select.Option value="DM">Dominica</Select.Option>
                      <Select.Option value="DO">Dominican Republic</Select.Option>
                      <Select.Option value="EC">Ecuador</Select.Option>
                      <Select.Option value="EG">Egypt</Select.Option>
                      <Select.Option value="SV">El Salvador</Select.Option>
                      <Select.Option value="GQ">Equatorial Guinea</Select.Option>
                      <Select.Option value="ER">Eritrea</Select.Option>
                      <Select.Option value="EE">Estonia</Select.Option>
                      <Select.Option value="SZ">Eswatini</Select.Option>
                      <Select.Option value="ET">Ethiopia</Select.Option>
                      <Select.Option value="FK">Falkland Islands</Select.Option>
                      <Select.Option value="FO">Faroe Islands</Select.Option>
                      <Select.Option value="FJ">Fiji</Select.Option>
                      <Select.Option value="FI">Finland</Select.Option>
                      <Select.Option value="FR">France</Select.Option>
                      <Select.Option value="GF">French Guiana</Select.Option>
                      <Select.Option value="PF">French Polynesia</Select.Option>
                      <Select.Option value="TF">French Southern Territories</Select.Option>
                      <Select.Option value="GA">Gabon</Select.Option>
                      <Select.Option value="GM">Gambia</Select.Option>
                      <Select.Option value="GE">Georgia</Select.Option>
                      <Select.Option value="DE">Germany</Select.Option>
                      <Select.Option value="GH">Ghana</Select.Option>
                      <Select.Option value="GI">Gibraltar</Select.Option>
                      <Select.Option value="GR">Greece</Select.Option>
                      <Select.Option value="GL">Greenland</Select.Option>
                      <Select.Option value="GD">Grenada</Select.Option>
                      <Select.Option value="GP">Guadeloupe</Select.Option>
                      <Select.Option value="GU">Guam</Select.Option>
                      <Select.Option value="GT">Guatemala</Select.Option>
                      <Select.Option value="GG">Guernsey</Select.Option>
                      <Select.Option value="GN">Guinea</Select.Option>
                      <Select.Option value="GW">Guinea-Bissau</Select.Option>
                      <Select.Option value="GY">Guyana</Select.Option>
                      <Select.Option value="HT">Haiti</Select.Option>
                      <Select.Option value="HM">Heard & McDonald Islands</Select.Option>
                      <Select.Option value="HN">Honduras</Select.Option>
                      <Select.Option value="HK">Hong Kong SAR China</Select.Option>
                      <Select.Option value="HU">Hungary</Select.Option>
                      <Select.Option value="IS">Iceland</Select.Option>
                      <Select.Option value="IN">India</Select.Option>
                      <Select.Option value="ID">Indonesia</Select.Option>
                      <Select.Option value="IR">Iran</Select.Option>
                      <Select.Option value="IQ">Iraq</Select.Option>
                      <Select.Option value="IE">Ireland</Select.Option>
                      <Select.Option value="IM">Isle of Man</Select.Option>
                      <Select.Option value="IL">Israel</Select.Option>
                      <Select.Option value="IT">Italy</Select.Option>
                      <Select.Option value="JM">Jamaica</Select.Option>
                      <Select.Option value="JP">Japan</Select.Option>
                      <Select.Option value="JE">Jersey</Select.Option>
                      <Select.Option value="JO">Jordan</Select.Option>
                      <Select.Option value="KZ">Kazakhstan</Select.Option>
                      <Select.Option value="KE">Kenya</Select.Option>
                      <Select.Option value="KI">Kiribati</Select.Option>
                      <Select.Option value="XK">Kosovo</Select.Option>
                      <Select.Option value="KW">Kuwait</Select.Option>
                      <Select.Option value="KG">Kyrgyzstan</Select.Option>
                      <Select.Option value="LA">Laos</Select.Option>
                      <Select.Option value="LV">Latvia</Select.Option>
                      <Select.Option value="LB">Lebanon</Select.Option>
                      <Select.Option value="LS">Lesotho</Select.Option>
                      <Select.Option value="LR">Liberia</Select.Option>
                      <Select.Option value="LY">Libya</Select.Option>
                      <Select.Option value="LI">Liechtenstein</Select.Option>
                      <Select.Option value="LT">Lithuania</Select.Option>
                      <Select.Option value="LU">Luxembourg</Select.Option>
                      <Select.Option value="MO">Macao SAR China</Select.Option>
                      <Select.Option value="MG">Madagascar</Select.Option>
                      <Select.Option value="MW">Malawi</Select.Option>
                      <Select.Option value="MY">Malaysia</Select.Option>
                      <Select.Option value="MV">Maldives</Select.Option>
                      <Select.Option value="ML">Mali</Select.Option>
                      <Select.Option value="MT">Malta</Select.Option>
                      <Select.Option value="MH">Marshall Islands</Select.Option>
                      <Select.Option value="MQ">Martinique</Select.Option>
                      <Select.Option value="MR">Mauritania</Select.Option>
                      <Select.Option value="MU">Mauritius</Select.Option>
                      <Select.Option value="YT">Mayotte</Select.Option>
                      <Select.Option value="MX">Mexico</Select.Option>
                      <Select.Option value="FM">Micronesia</Select.Option>
                      <Select.Option value="MD">Moldova</Select.Option>
                      <Select.Option value="MC">Monaco</Select.Option>
                      <Select.Option value="MN">Mongolia</Select.Option>
                      <Select.Option value="ME">Montenegro</Select.Option>
                      <Select.Option value="MS">Montserrat</Select.Option>
                      <Select.Option value="MA">Morocco</Select.Option>
                      <Select.Option value="MZ">Mozambique</Select.Option>
                      <Select.Option value="MM">Myanmar (Burma)</Select.Option>
                      <Select.Option value="NA">Namibia</Select.Option>
                      <Select.Option value="NR">Nauru</Select.Option>
                      <Select.Option value="NP">Nepal</Select.Option>
                      <Select.Option value="NL">Netherlands</Select.Option>
                      <Select.Option value="AN">Netherlands Antilles</Select.Option>
                      <Select.Option value="NC">New Caledonia</Select.Option>
                      <Select.Option value="NZ">New Zealand</Select.Option>
                      <Select.Option value="NI">Nicaragua</Select.Option>
                      <Select.Option value="NE">Niger</Select.Option>
                      <Select.Option value="NG">Nigeria</Select.Option>
                      <Select.Option value="NU">Niue</Select.Option>
                      <Select.Option value="NF">Norfolk Island</Select.Option>
                      <Select.Option value="MP">Northern Mariana Islands</Select.Option>
                      <Select.Option value="KP">North Korea</Select.Option>
                      <Select.Option value="MK">North Macedonia</Select.Option>
                      <Select.Option value="NO">Norway</Select.Option>
                      <Select.Option value="OM">Oman</Select.Option>
                      <Select.Option value="QO">Outlying Oceania</Select.Option>
                      <Select.Option value="PK">Pakistan</Select.Option>
                      <Select.Option value="PW">Palau</Select.Option>
                      <Select.Option value="PS">Palestinian Territories</Select.Option>
                      <Select.Option value="PA">Panama</Select.Option>
                      <Select.Option value="PG">Papua New Guinea</Select.Option>
                      <Select.Option value="PY">Paraguay</Select.Option>
                      <Select.Option value="PE">Peru</Select.Option>
                      <Select.Option value="PH">Philippines</Select.Option>
                      <Select.Option value="PN">Pitcairn Islands</Select.Option>
                      <Select.Option value="PL">Poland</Select.Option>
                      <Select.Option value="PT">Portugal</Select.Option>
                      <Select.Option value="PR">Puerto Rico</Select.Option>
                      <Select.Option value="QA">Qatar</Select.Option>
                      <Select.Option value="RO">Romania</Select.Option>
                      <Select.Option value="RU">Russia</Select.Option>
                      <Select.Option value="RW">Rwanda</Select.Option>
                      <Select.Option value="RE">Réunion</Select.Option>
                      <Select.Option value="WS">Samoa</Select.Option>
                      <Select.Option value="SM">San Marino</Select.Option>
                      <Select.Option value="SA">Saudi Arabia</Select.Option>
                      <Select.Option value="SN">Senegal</Select.Option>
                      <Select.Option value="RS">Serbia</Select.Option>
                      <Select.Option value="SC">Seychelles</Select.Option>
                      <Select.Option value="SL">Sierra Leone</Select.Option>
                      <Select.Option value="SG">Singapore</Select.Option>
                      <Select.Option value="SX">Sint Maarten</Select.Option>
                      <Select.Option value="SK">Slovakia</Select.Option>
                      <Select.Option value="SI">Slovenia</Select.Option>
                      <Select.Option value="SB">Solomon Islands</Select.Option>
                      <Select.Option value="SO">Somalia</Select.Option>
                      <Select.Option value="ZA">South Africa</Select.Option>
                      <Select.Option value="GS">South Georgia & South Sandwich Islands</Select.Option>
                      <Select.Option value="KR">South Korea</Select.Option>
                      <Select.Option value="SS">South Sudan</Select.Option>
                      <Select.Option value="ES">Spain</Select.Option>
                      <Select.Option value="LK">Sri Lanka</Select.Option>
                      <Select.Option value="BL">St. Barthélemy</Select.Option>
                      <Select.Option value="SH">St. Helena</Select.Option>
                      <Select.Option value="KN">St. Kitts & Nevis</Select.Option>
                      <Select.Option value="LC">St. Lucia</Select.Option>
                      <Select.Option value="MF">St. Martin</Select.Option>
                      <Select.Option value="PM">St. Pierre & Miquelon</Select.Option>
                      <Select.Option value="VC">St. Vincent & Grenadines</Select.Option>
                      <Select.Option value="SD">Sudan</Select.Option>
                      <Select.Option value="SR">Suriname</Select.Option>
                      <Select.Option value="SJ">Svalbard & Jan Mayen</Select.Option>
                      <Select.Option value="SE">Sweden</Select.Option>
                      <Select.Option value="CH">Switzerland</Select.Option>
                      <Select.Option value="SY">Syria</Select.Option>
                      <Select.Option value="ST">São Tomé & Príncipe</Select.Option>
                      <Select.Option value="TW">Taiwan</Select.Option>
                      <Select.Option value="TJ">Tajikistan</Select.Option>
                      <Select.Option value="TZ">Tanzania</Select.Option>
                      <Select.Option value="TH">Thailand</Select.Option>
                      <Select.Option value="TL">Timor-Leste</Select.Option>
                      <Select.Option value="TG">Togo</Select.Option>
                      <Select.Option value="TK">Tokelau</Select.Option>
                      <Select.Option value="TO">Tonga</Select.Option>
                      <Select.Option value="TT">Trinidad & Tobago</Select.Option>
                      <Select.Option value="TA">Tristan da Cunha</Select.Option>
                      <Select.Option value="TN">Tunisia</Select.Option>
                      <Select.Option value="TR">Turkey</Select.Option>
                      <Select.Option value="TM">Turkmenistan</Select.Option>
                      <Select.Option value="TC">Turks & Caicos Islands</Select.Option>
                      <Select.Option value="TV">Tuvalu</Select.Option>
                      <Select.Option value="UM">U.S. Outlying Islands</Select.Option>
                      <Select.Option value="VI">U.S. Virgin Islands</Select.Option>
                      <Select.Option value="UG">Uganda</Select.Option>
                      <Select.Option value="UA">Ukraine</Select.Option>
                      <Select.Option value="AE">United Arab Emirates</Select.Option>
                      <Select.Option value="GB">United Kingdom</Select.Option>
                      <Select.Option value="US">United States</Select.Option>
                      <Select.Option value="UY">Uruguay</Select.Option>
                      <Select.Option value="UZ">Uzbekistan</Select.Option>
                      <Select.Option value="VU">Vanuatu</Select.Option>
                      <Select.Option value="VA">Vatican City</Select.Option>
                      <Select.Option value="VE">Venezuela</Select.Option>
                      <Select.Option value="VN">Vietnam</Select.Option>
                      <Select.Option value="WF">Wallis & Futuna</Select.Option>
                      <Select.Option value="EH">Western Sahara</Select.Option>
                      <Select.Option value="YE">Yemen</Select.Option>
                      <Select.Option value="ZM">Zambia</Select.Option>
                      <Select.Option value="ZW">Zimbabwe</Select.Option>
                      <Select.Option value="AX">Åland Islands</Select.Option>
                    </Select>
                  </Form.Item>
                
                  <Form.Item name='title'
                    className={classes.item}
                    label={<label className={classes.itemName}>{t('Τίτλος')}</label>}
                    rules={[{ required: true }]}>
                      <Input className={classes.input}/>
                  </Form.Item>
                

                  <Form.Item name='address'
                    className={classes.item}
                    label={<label className={classes.itemName}>{t('Διεύθυνση')}</label>}
                    rules={[{ required: true }]}>
                      <Input className={classes.input}/>
                  </Form.Item>
                
                  <Form.Item name='city'
                    className={classes.item}
                    label={<label className={classes.itemName}>{t('Πόλη')}</label>}
                    rules={[{ required: true }]}>
                      <Input className={classes.input}/>
                  </Form.Item>
                
                  <Form.Item name='postCode'
                    className={classes.item}
                  label={<label className={classes.itemName}>{t('Ταχυδρομικός κώδικας')}</label>}
                    rules={[{ required: true }]}
                  >
                      <Input className={classes.input}/>
                  </Form.Item>

                  <Form.Item name='company'
                    className={classes.item}
                    label={<label className={classes.itemName}>{t('Επωνυμία εταιρείας')}</label>}
                    rules={[{ required: true }]}>
                      <Input className={classes.input}/>
                  </Form.Item>

                  <Form.Item name='activity'
                    className={classes.item}
                    label={<label className={classes.itemName}>{t('Δραστηριότητα')}</label>}
                    rules={[{ required: true }]}>
                      <Input className={classes.input}/>
                  </Form.Item>
                  
                  <Form.Item name='contactPerson'
                    className={classes.item}
                    label={<label className={classes.itemName}>{t('Πρόσωπο επικοινωνίας')}</label>}
                    rules={[{ required: true }]}>
                      <Input className={classes.input}/>
                  </Form.Item>
                  
                  <Form.Item name='phone'
                    className={classes.item}
                    label={<label className={classes.itemName}>{t('Τηλέφωνο')}</label>}
                    rules={[{ required: true }]}>
                      <Input className={classes.input}/>
                  </Form.Item>

                  <Form.Item name='email'
                    className={classes.item}
                    label={<label className={classes.itemName}>E-mail</label>}
                    rules={[{ required: true }]}>
                      <Input className={classes.input}/>
                  </Form.Item>
                  
                  <Form.Item name='web'
                    className={classes.item}
                    label={<label className={classes.itemName}>{t('Ιστοσελίδα')}</label>}
                    rules={[
                      { required: true },
                      {
                        type: "url",
                        message: "This field must be a valid url."
                      }
                    ]}>
                      <Input className={classes.input}/>
                  </Form.Item>
                  
                  <Form.Item name='facebook'
                    className={classes.item}
                    label={<label className={classes.itemName}>Facebook</label>}>
                      <Input className={classes.input}/>
                  </Form.Item>
                  <Form.Item name='instagram'
                    className={classes.item}
                    label={<label className={classes.itemName}>Instagram</label>}>
                      <Input className={classes.input}/>
                  </Form.Item>
                  <Form.Item name='linkedIn'
                    className={classes.item}
                    label={<label className={classes.itemName}>LinkedIn</label>}>
                      <Input className={classes.input}/>
                  </Form.Item>
                  <Form.Item name='youtube'
                    className={classes.item}
                    label={<label className={classes.itemName}>YouTube</label>}>
                      <Input className={classes.input}/>
                 </Form.Item>
                
                  <Form.Item name='product'
                    className={classes.item}
                    label={<label className={classes.itemName}>{t('Προϊόντα')}</label>}>
                      <Input className={classes.input}/>
                  </Form.Item>

                {/* <Form.Item name={['user', 'introduction']} label="Introduction">
                    <Input.TextArea />
                </Form.Item> */}
                
                {/* <Form.Item wrapperCol={{ xs: { span: 18, offset: 0 }, sm: { span: 4, offset: 16 }, md: { span: 4, offset: 16 } }}> */}
                <Form.Item wrapperCol={{ md: { span: 23 }, lg: { span: 16 } }}>
                  <Button type="primary" shape="round" htmlType="submit" loading={save} className={save ? classes.btnSaveCancel : classes.btnSave}>{t('ΑΠΟΘHΚΕΥΣΗ')}</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button block shape="round" className={classes.btnCancel} onClick={onReset}> {t('ΑΚΥΡΟ')} </Button>
                </Form.Item>
                
                
              </Form>

              </div>
            </Col>
          </Row>
        </div>
    </>
  )
}

export default CreateDirectory

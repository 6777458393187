import { useState } from "react"
import { useQuery } from '@tanstack/react-query'
import { EventsProps, ExportDirectoryProps } from "../services/props"
import { axiosApi } from "../services/api"
import i18n from '../../src/translation'



export const useGetTitles = () => {
  
  const [GetTitles, setGetTitles] = useState<any[]>([])
  const [GetTitlesCheck, setGetTitlesCheck] = useState<any[]>([])

  let TitlesArray: any[] = []
  let TitlesArrayCheck: any[] = []

  const { isLoading, isFetching, error } = useQuery(['getTitles'], () => axiosApi.getTitles(), {

    onSuccess: (data: any) => {

      data.map((array: any) => {
        TitlesArray.push({ id: array.id, value: array.title })
        TitlesArrayCheck.push( array.title )
    })
      
      setGetTitles(TitlesArray) 
      setGetTitlesCheck(TitlesArrayCheck) 
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, GetTitles, GetTitlesCheck }
}






export const useEvents = () => {
  
  const [Events, setEvents] = useState<EventsProps[]>([])

  let eventsArray: EventsProps[] = []

  const optionsForDate = { hour12: false, day: 'numeric', month: 'short', year: 'numeric' } as const;

  const { isLoading, isFetching, error, refetch } = useQuery(['events'], () => axiosApi.getEvents(), {

    // refetchOnWindowFocus: false,
    // enabled: false,

    onSuccess: (data: EventsProps[]) => {
      
      data.map((array: any) => {

        eventsArray.push({
            id: array.id,
            date: new Intl.DateTimeFormat(i18n.language == 'gr' ? 'el-GR' : 'en-US', optionsForDate).format((array.start_date * 1000)),
            event: array.title,
            city: array.location,
            modalText: array.description,
            shortDescription: array.short_description,
            info: array.info,
            languages: array.languages

          })
      })

      setEvents(eventsArray) 
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, Events, refetch }
}


export const useExportDirectory = () => {
  
  const [ExportDirectory, setExportDirectory] = useState<ExportDirectoryProps[]>([])

  let exportDirectoryArray: ExportDirectoryProps[] = []


  const { isLoading, isFetching, error, refetch } = useQuery(['exportDirectory'], () => axiosApi.getExportDirectory(), {

    // refetchOnWindowFocus: false,
    // enabled: false,

    onSuccess: (data: ExportDirectoryProps[]) => {
      
      data.map((array: any) => {

        exportDirectoryArray.push({
            id: array.id,
            logo: array.logo !== null ? array.logo.url : '',
            title: array.title,
            company_name: array.title,
            activity: array.activity,
            contact_person: array.contact_person,
            products: array.products,
            telephone: array.telephone,
            email: array.email,
            address: array.address,
            website: array.website !== null ? array.website.url : '',
            social: array.social,
          })
      })

      setExportDirectory(exportDirectoryArray) 
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, ExportDirectory, refetch }
}


export const useExportToolkit = () => {
  
  const [ExportToolkitData, setExportToolkit] = useState<any[]>([])

  let exportToolkitArray: any[] = []

  const { isLoading, isFetching, error, refetch } = useQuery(['exportToolkit'], () => axiosApi.getExportToolkit(), {

    onSuccess: (data: ExportDirectoryProps[]) => {
      
      data.map((array: any) => {

        exportToolkitArray.push({
            id: array.id,
            title: array.title,
            description: array.description,
            files: array.files,
            links: array.links,
            videos: array.videos
          })
      })

      setExportToolkit(exportToolkitArray) 
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, ExportToolkitData, refetch }
}


export const useAdvertorial = () => {
  
  const [AdvertorialData, setAdvertorial] = useState<any[]>([])

  let AdvertorialArray: any[] = []
  let AdvertorialImagesArray: any[] = []

  const { isLoading, isFetching, error, refetch } = useQuery(['Advertorial'], () => axiosApi.getAdvertorial(), {

    onSuccess: (data: any) => {
      
      data.map((array: any) => {


        array.photos.map((image:any, index:number) => {

          AdvertorialImagesArray.push({
            id: index,
            src: image.url,
            original: image.url,
            width: 320,
            height: 174,
            caption: ''
          })
        })

        AdvertorialArray.push({
            id: array.id,
            title: array.title,
            description: array.description,
            files: array.files,
            links: array.links,
            photos: AdvertorialImagesArray,
            videos: array.videos
        })
        
        AdvertorialImagesArray = []

      })

      setAdvertorial(AdvertorialArray) 
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, AdvertorialData, refetch }
}



// export const useEventsMap = () => {
  
//   const [Events, setEvents] = useState<GeneralProps[]>([]);
//   const { isLoading, error } = useQuery(['events'], () => axiosEvents.getEvents(), {

//     onSuccess: (data) => {

//       let key = 0
//       let array: GeneralProps[] = []
//       let lat = '0'
//       let lng = '0'
//       let hours = ''
//       let start_date = ''
//       let end_date = ''
//       let timePeriodsArray: any[] = []
//       let shortMonth: any[] = []
      
//       const optionsForDate = { hour12: false, hour: '2-digit', minute: '2-digit', day: 'numeric', month: '2-digit', year: 'numeric' } as const;
      
    
//       data.map((listData: any) => {

//         key++

//         let MonthsArray: any[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

//         if (listData.time_periods != '') {

//           let startMonth = 0
//           let finishMonth = 0
//           let removedItems: any[] = []

//           let starting_date = new Intl.DateTimeFormat('en-US', { month: 'short' }).format((listData.time_periods[0].starting_date * 1000))
//           let ending_date = new Intl.DateTimeFormat('en-US', { month: 'short' }).format((listData.time_periods[0].ending_date * 1000))

//           if (starting_date == ending_date) {
//               removedItems = [starting_date]
//           }

//           for (var i = 0; i < MonthsArray.length; i++) {

//             if (MonthsArray[i] == starting_date)
//               startMonth = MonthsArray.indexOf(MonthsArray[i]);
            
//             if (MonthsArray[i] == ending_date)
//               finishMonth = MonthsArray.indexOf(MonthsArray[i]);
//           }


//           if (startMonth < finishMonth) {
//             removedItems = MonthsArray.splice(startMonth, finishMonth - startMonth + 1)
//           }

//           else if (startMonth > finishMonth) {
//             removedItems = MonthsArray.splice(startMonth, startMonth - finishMonth)
//             removedItems = [...removedItems, ending_date]
//           }
  
//           shortMonth.push([...removedItems])

//         }

//         else{
//           shortMonth.push(...MonthsArray)
//         }

          
//         if (listData.venues.length != 0 && listData.venues[0].coordinate_x != null) {
//           lat = listData.venues[0].coordinate_x
//           lng = listData.venues[0].coordinate_y
//         }
//         else {
//           lat = '0'
//           lng = '0'
//         }

//         if (listData.time_periods.length == 0) {
          
//           hours = '12:00 - 12:00'
//           start_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(434800800 * 1000) 
//           end_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(434800800 * 1000) 
//           timePeriodsArray.push({ starting_date: start_date, ending_date: end_date })
          
//         } 	
//         else {
//           hours = `${new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).format((listData.time_periods[0].starting_date * 1000))} - ${new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).format((listData.time_periods[0].ending_date * 1000))}`
          
//           for (let i = 0; i < listData.time_periods.length; i++){

//             start_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(listData.time_periods[i].starting_date * 1000)
//             end_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(listData.time_periods[i].ending_date * 1000)
          
//             timePeriodsArray.push({ starting_date: start_date, ending_date: end_date })
//           }
//         }
            
//         array.push({
//           id: `event_${listData.id.toString()}_${key}`,
//           id_item: listData.id.toString(),
//           image: listData.image.url,
//           title: listData.title,
//           description: listData.description,
//           lat: lat,
//           lng: lng,
//           time: '',
//           distance: '',
//           route_type:'',
//           category_id: listData.category.id,
//           category_type: listData.category.title,
//           month_code: shortMonth,
//           hour: hours,
//           price: listData.tickets,    
//           time_periods: timePeriodsArray,
//           venues: listData.venues
//         })

//         timePeriodsArray=[]
//         shortMonth=[]
//       });

//       setEvents(array)
//     },

//     onError: (error:any) => {
//       alert(error.message)
//     }
//   })

//   return { isLoading, error, Events }
// }



// function setArray(title: string, array: any, setArray:any) {
  
//     let Id: number = 0
//     let Cardtitle: string = ''
//     let Thumbnail: string = ''
//     let Type: string = ''
//     let Longtitude: string = ''
//     let Lantitude: string = ''
//     let Description: string = ''
//     let Images: string = ''
//     let arrayImages: any[] = []
  
    
//     for (var item of Object.keys(array)) { 
//       switch (item) {
//         case 'o:id': Id = array[item]; break;
//         case 'o:title': Cardtitle = array[item]; break;
//         case 'thumbnail_display_urls': Thumbnail = array[item]['large']; break;
//         case 'dcterms:subject': Type = array[item][0]['@value']; break;
//         case 'dotsoft:longtitude': Longtitude = array[item][0]['@value']; break;
//         case 'dotsoft:lantitude': Lantitude = array[item][0]['@value']; break;
//         case 'dotportal:mobileDescription': Description = array[item][0]['@value']; break;
//         case 'mobilemedia:lowResImages': Images = array[item][0]['@value']; break;
//       }
//     }
  
//     let images = Images.split('\n') 
//     for (let i in images) {
//       arrayImages.push({
//         id: 'image_' + (parseInt(i)+1),
//         url: images[i],
//         alt: Cardtitle+' '+(parseInt(i)+1)
//       })
//     }

//     setArray.push({
//       id: Id ,
//       title: title,
//       cardTitle: Cardtitle , 
//       media: {
//         name: Cardtitle ,
//         source: { url: Thumbnail  },
//         type: "IMAGE",
//       },
//       cardDetailedText: Description,
//       Images: arrayImages
//     })

// }

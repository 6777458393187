import React, {useEffect} from 'react'
import { Row, Col} from 'antd'
import classes from "./css/Events.module.css"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import i18next from 'i18next'
import { useEvents } from '../../../hooks/useApi'

import EventsComponent from '../../../components/Events'

const Events: React.FC = () => {

  const { t } = useTranslation()
  const { isFetching, Events, refetch } = useEvents()

  useEffect(() => {
    refetch()
  }, [i18next.language])

  if(isFetching) { return null }
 
  return (
    <>
    <div className={classes.backgroundGrey}>
      <div className={classes.container}>
        <h3>{t("Εκδηλώσεις")} </h3>
        <Row justify="center">
          <EventsComponent  number={8} events={Events} />
        </Row>
          
        <Row justify="center">
          <Col xs={24} sm={24} lg={24} >
              <div className={classes.seeMoreDiv}>
                <span className={classes.seeMoreSpan}><Link to={'events'}>{t('Δείτε περισσότερα Νέα και Εκδηλώσεις')}</Link></span>
              </div>
          </Col>
        </Row>

      </div>
    </div>
    </>
  )
}

export default Events

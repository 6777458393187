import React, { useState, useEffect }  from 'react'
import { Row, Col } from 'antd'
import classes from "./styles.module.css"
import { AboutUsData } from './AboutUsData'
import { AboutProjectData } from './AboutProjectData'
import { FundingProgramsData } from './FundingProgramsData'
import { useLocation } from "react-router-dom"
import i18next from 'i18next'
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../components/Helmet'
  
const AboutUs: React.FC = () => {

  const [dataTitle, setDataTitle] = useState('')
  const [dataText, setDataText] = useState('')
  const location = useLocation()
  const { t } = useTranslation()

  useEffect(() => {

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    switch (location.state.page) {
      case 'about_project':
        setDataTitle(AboutProjectData[0][i18next.language == 'en' ? 'en' : i18next.language == 'gr' ? 'gr' : 'bg'].title);
        setDataText(AboutProjectData[0][i18next.language == 'en' ? 'en' : i18next.language == 'gr' ? 'gr' : 'bg'].text);
        break;
      case 'about_us':
        setDataTitle(AboutUsData[0][i18next.language == 'en' ? 'en' : i18next.language == 'gr' ? 'gr' : 'bg'].title);
        setDataText(AboutUsData[0][i18next.language == 'en' ? 'en' : i18next.language == 'gr' ? 'gr' : 'bg'].text);
        break;
      case 'funding_programs':
        setDataTitle(FundingProgramsData[0][i18next.language == 'en' ? 'en' : i18next.language == 'gr' ? 'gr' : 'bg'].title);
        setDataText(FundingProgramsData[0][i18next.language == 'en' ? 'en' : i18next.language == 'gr' ? 'gr' : 'bg'].text);
        break;
    }


  }, [i18next.language, location.state.page])

  return (
    <>
      <HelmetComponent title='SEO Common page title' descrition='SEO Common page description' />
      <div className={classes.backgroundGrey}>
        <div className={classes.container}>
          <Row justify="center">
            <Col lg={{span : 20}} xs={{span:24}}>
              <div className={classes.container}>
                <div className={classes.title} dangerouslySetInnerHTML={{ __html: dataTitle }} ></div>
                <div className={classes.text} dangerouslySetInnerHTML={{ __html: dataText }} ></div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default AboutUs

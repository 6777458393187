import React, { useState, useEffect } from 'react'
import { MenuOutlined, ArrowDownOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import classes from "./Navbar.module.css"
import { useSelector } from 'react-redux'
import type { RootState } from '../../redux/store'


const Navbar = () => {

  const { t } = useTranslation()
  
  const [toggleMenu, setToggleMenu] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  

  const Login: any[] = useSelector((state: RootState) => state.Logged.Login) 


  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }

  useEffect(() => {

    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

      window.addEventListener('resize', changeWidth)

    return () => {
      window.removeEventListener('resize', changeWidth)
    }

  }, [])

    return (
      <nav>
        {(toggleMenu || screenWidth > 1280) && (
          <ul className={ [classes.list, classes.items].join(" ")}>
            <Link to="/" onClick={toggleNav} >{t('Αρχική')}</Link>
            <Link to="/page/about_project" state={{ page:'about_project' }} onClick={toggleNav} >{t('Σχετικά με το έργο')}</Link>
            <Link to="/page/about_us" state={{ page:'about_us' }} onClick={toggleNav} >{t('Σχετικά με εμάς')}</Link>
            <Link to="/page/funding_programs" state={{ page:'funding_programs' }} onClick={toggleNav} >{t('Το πρόγραμμα χρηματοδότησης')}</Link>
            <Link to="/events" onClick={toggleNav} >{t('Εκδηλώσεις')}</Link>
            {/* <a href="https://gointfoodexpo.vfairs.com/" onClick={toggleNav} target="_blank" >{t('Εικονικές Εκδηλώσεις')}</a> */}
           
            <div className={ classes.dropdown} >
              <button className={ classes.dropbtn}>{t('Εικονικές Εκδηλώσεις')}</button>
              <div className={ classes.dropdownContent}>
                <a href="https://gointfoodexpo.vfairs.com/">GoInt Food Expo</a>
                <a href="https://gointetools.vfairs.com/">GoInt E-tools</a>
              </div>
            </div>

            {/* {Login.length !== 0 && */}
            { localStorage.getItem('access_token') !== null &&
              <div className={classes.dropdown} >
                <button className={classes.dropbtn}>{t('For Members Only')} <span style={{ marginLeft: '2px' }}><ArrowDownOutlined /></span></button>
                <div className={classes.dropdownContent}>
                  <Link to="/edit_profile" >{t('Επεξεργασία Προφίλ')}</Link>
                  <Link to="/create_directory" >{t('Καταχώρηση σε Directory')}</Link>
                  <Link to="/create_advertorial" >{t('Καταχώρηση σε Advertorial')}</Link>
                  <Link to="/export_directory" >Export Directory</Link>
                  <Link to="/export_toolkit" >Export Toolkit</Link>
                  <Link to="/advertorial" >Advertorial</Link>
                </div>
              </div>
            }

          </ul>
        )}
        <button onClick={toggleNav} className={classes.btn}><MenuOutlined /></button>
      </nav>
    )

}
export default Navbar
import React from "react"
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import classes from "./Footer.module.css"



function Footer() {

  const { t } = useTranslation()

  return (
    <>
      <div className={classes.footerContainer}>
        <Row justify="center">
          <Col xs={24} sm={24} md={6} lg={6} span={2}>
            <a href="https://www.sme.government.bg/en/" target="_blank"><div className={classes.footerImg1}></div></a>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} span={2}>
            <a href="https://griechenland.ahk.de/gr/" target="_blank"><div className={classes.footerImg2}></div></a>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} span={2}>
            <a href="https://www.italchamber.gr/" target="_blank"><div className={classes.footerImg3}></div></a>
          </Col>
        </Row>

        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24}>
            <div className={classes.footerDiv}>
              <div>{t('footer_text_1')}</div>
              <div>{t('footer_text_2')}</div>
            </div>
          </Col>
        </Row>

        
        <Row justify="center">
          <Col xs={24} sm={24} md={8} lg={9} span={4}>
            <div className={classes.FollowUs}><span>{t('Ακολουθήστε μας')}</span>
              <a href="https://www.facebook.com/GoIntProject" target="_blank">
                <img src="../img/Footer/social/facebookwhite.png" alt="facebook" />
              </a>
              {/* <a href="https://react-redux.js.org/tutorials/typescript-quick-start" target="_blank">
                <img src="../img/Footer/social/instagramwhite.png" alt="instagram" />
              </a>
              <a href="https://react-redux.js.org/tutorials/typescript-quick-start" target="_blank">
                <img src="../img/Footer/social/linkedinwhite.png" alt="linkedIn" />
              </a>
              <a href="https://react-redux.js.org/tutorials/typescript-quick-start" target="_blank">
                <img src="../img/Footer/social/twitterwhite.png" alt="twitter" /> 
              </a>*/}
            </div> 
          </Col>
         
          <Col xs={24} sm={24} md={8} lg={9} span={4}>
            <div className={classes.AllRigts}><span>® Powered By <a href="https://www.dotsoft.gr/" target="_blank">Dotsoft</a></span></div>
          </Col>
        </Row>


      </div>
    </>
  )
}

export default Footer;
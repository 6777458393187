import axios from 'axios'
import { API_URL } from "./configuration"
import i18n from '../../src/translation'

export const axiosApi = {


  async getTitles() {
    const { data } = await axios.get(`${API_URL}/export_titles`)

    return data
  },


  async getEvents() {
    const { data } = await axios.get(`${API_URL}/events?lang=${i18n.language == 'gr' ? 'el' : i18n.language}`)
    return data
  },


  async getExportDirectory() {
    const { data } = await axios.get(`${API_URL}/export_directory?lang=${i18n.language == 'gr' ? 'el' : i18n.language}`,{
      headers: {
        "Authorization": `Bearer ${window.localStorage.getItem("access_token")}`,
      }
    })
    return data
  },


  async getExportToolkit() {
    const { data } = await axios.get(`${API_URL}/export_toolkit?lang=${i18n.language == 'gr' ? 'el' : i18n.language}`,{
      headers: {
        "Authorization": `Bearer ${window.localStorage.getItem("access_token")}`,
      }
    })
    return data
  },


  async getAdvertorial() {
    const { data } = await axios.get(`${API_URL}/advertorials?lang=${i18n.language == 'gr' ? 'el' : i18n.language}`, {
      headers: {
        "Authorization": `Bearer ${window.localStorage.getItem("access_token")}`,
      }
    })

    return data
  },


  async postPdf(formData: any) {
    
    console.log('formdata', formData)
    const pdf = { data: formData }
    const lalala = { title: 'sergios' }
    const headers = { 
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      "Content-Type": "multipart/form-data",


      // "Content-Type":"application/octet-stream",
      "Content-Disposition":"file; filename='file1.pdf'",
      "X-Requested-With":"XMLHttpRequest",
      "Accept-Encoding":"gzip, deflate",
      "Accept":"application/vnd.api+json"
    }

    // try {
      const data = await axios.post(`https://my-json-server.typicode.com/typicode/demo/posts`, formData,  {
        withCredentials: false,
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
    })
      // const data = await axios.post(`https://my-json-server.typicode.com/typicode/demo/posts`, pdf, { headers })
        .then((response) => {
        console.log('response ', response );
      }).catch(err => {
            console.log("err", err);
      });
    
    
      return null
    
    }
    // catch(error) {
    //   console.log('Error : ', error)
    // }



    

    // try {
    //   const data = await fetch(`${API_URL}/node/advertorial/field_advertorial_files`, {
    //     method: 'POST',
    //     body: '',
    //     headers: {
          
    //       "Access-Control-Allow-Origin": "*",
    //       "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    //       "Content-Type":"application/octet-stream",
    //       "Content-Disposition":"file; filename='sample.pdf'",
    //       "X-Requested-With":"XMLHttpRequest",
    //       "Accept-Encoding":"gzip, deflate",
    //       "Accept": "application/vnd.api+json"


    //     }

    //   }).then((response) => {
    //     console.log('response ', response );
    //   }).catch(err => {
    //     console.log("err", err);
    //   });
    // }
    // catch(error) {
    //   console.log('Error : ', error)
    // }



    // const { data } = await axios.get(`${API_URL}/events?lang=${i18n.language == 'gr' ? 'el' : i18n.language}`)
    // return null
  // },
}
import React, { useState, useEffect } from 'react'
import axios from 'axios' 
import { Row, Col, Button, Form, Input, Divider, message } from 'antd'
import classes from "./styles.module.css"
import { UserProps } from "../../services/props"
import i18next from 'i18next'
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../components/Helmet'
import { useUserProfile } from '../../hooks/useUser'
import { MailOutlined, UserOutlined, IdcardOutlined } from '@ant-design/icons';
import type { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'

const EditProfile: React.FC = () => {

  const { t } = useTranslation()

  const [form] = Form.useForm()

  const { isFetching, User } = useUserProfile()

  const [save, setSave] = useState<boolean>(false)
  
  const Login: any[] = useSelector((state: RootState) => state.Logged.Login)

  const [messageApi, successMessage] = message.useMessage()
  const [messageApiError, errorMessage] = message.useMessage()

  // console.log('User', User)
  // console.log('Login', Login[0])

  useEffect(() => {

    if (User !== undefined) {
      form.setFieldsValue({ name: User.name })
      form.setFieldsValue({ email: User.email })
      form.setFieldsValue({ company: User.company })  
    }
  }, [User])

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Password Changed Successfully',
      className: 'custom-class',
      style: {
        marginTop: '20vh',
      },
    });
  };

  const wrong = () => {
    messageApiError.open({
      type: 'error',
      content: 'Something wrong',
      className: 'custom-class',
      style: {
        marginTop: '20vh',
      },
    });
  };


  const onReset = () => {
    form.resetFields();
  }


  const layout = { labelCol: {  sm:{ span:12 }, md:{ span:12 }, lg:{ span:8 }   }, wrapperCol: {  sm:{ span:12 }, md:{ span:12 }, lg:{ span:16 } } }

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }


  const onFinish = (values: any) => {
    // console.log(values)

    setSave(true)

    axios({
      method: 'patch',
      url: `https://goint-admin.dotsoft.gr/api/user/user/${User.id}`, //API url
      data: `
      {
        "data": {
            "type": "user--user",
            "id": "${User.id}",
            "attributes": {
                "pass": [
                    {
                        "existing": "${values.oldPassword}",
                        "value": "${values.newPassword}"
                    }
                ]
            }
        }
    }`,
      timeout: 2000,
      headers: {
        "Content-Type": "application/vnd.api+json",
        "Accept": "application/vnd.api+json",
        "X-Requested-With": "XMLHttpRequest",
        "Authorization": `Bearer ${Login[0]}`
      }

    }).then(({ data }) => {
      success(),  
      setSave(false),
      window.scrollTo(0, 0)
    }).catch(err => [console.log("err", err), wrong()])

  }


  if (isFetching || !User) { return <div className={classes.container}></div> }
  
  return (
    <>
      <span>
        {successMessage}
      </span>
      <span> 
        {errorMessage}
      </span>
      <HelmetComponent title='Edit Profile Page' descrition='Edit Profile Page' />
        <div className={classes.container}>
          <Row justify="center">
            <Col  xs={{span:24}} lg={{span : 20}}>
              <div className={classes.container}>

                <div className={classes.titleMain}><span>{t('Επεξεργασία Προφίλ')}</span></div>

              <Form
                {...layout}
                form={form}
                  name="nest-messages"
                  onFinish={onFinish}
                  style={{ textAlign: 'right' }}
                  validateMessages={validateMessages}
              >
                
                  <Form.Item name='name'
                    className={classes.item}
                    label={<label className={classes.itemName}>{t('Όνομα')}</label>}
                    rules={[{ required: true }]}>
                  <Input className={classes.input} prefix={<UserOutlined />} defaultValue={User.name} disabled />
                  </Form.Item>
                
                  <Form.Item name='email'
                    className={classes.item}
                    label={<label className={classes.itemName}>Email</label>}
                    rules={[{ required: true, type: 'email' }]}>
                    <Input className={classes.input} prefix={<MailOutlined />} defaultValue={User.email} disabled/>
                  </Form.Item>
                
                  <Form.Item name='company'
                    className={classes.item}
                    label={<label className={classes.itemName}>{t('Επωνυμία εταιρείας')}</label>}
                    rules={[{ required: true }]}>
                    <Input className={classes.input} prefix={ <IdcardOutlined /> }  defaultValue={User.company} disabled/>
                  </Form.Item>


                <Divider orientation="left">{t('Αλλαγή password')}</Divider><br/>


                  <Form.Item name="oldPassword"
                    className={classes.item}
                    label={<label className={classes.itemName}>{t('Παλιός password')}</label>}
                    rules={[
                      {
                        required: true,
                        message: 'Please input old password!',
                      },
                    ]}
                    hasFeedback>
                    <Input.Password className={classes.input}/>
                  </Form.Item>


                  <Form.Item name="newPassword"
                    className={classes.item}
                    label={<label className={classes.itemName}>{t('Νέος password')}</label>}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                    hasFeedback>
                    <Input.Password className={classes.input}/>
                  </Form.Item>

                  <Form.Item name="confirm"
                    className={classes.item}
                    label={<label className={classes.itemName}>{t('Επιβεβαίωση κωδικού')}</label>}
                    dependencies={['newPassword']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('newPassword') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The new password that you entered do not match!'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password className={classes.input}/>
                </Form.Item>
                

                
                {/* <Form.Item name={['user', 'introduction']} label="Introduction">
                    <Input.TextArea />
                </Form.Item> */}
                
                {/* <Form.Item wrapperCol={{ xs: { span: 18, offset: 0 }, sm: { span: 4, offset: 16 }, md: { span: 4, offset: 16 } }}> */}
                <Form.Item wrapperCol={{ md: { span: 23 }, lg: { span: 16 } }}>
                  <Button type="primary" shape="round" htmlType="submit" loading={save} className={save ? classes.btnSaveCancel : classes.btnSave}>{t('ΑΠΟΘHΚΕΥΣΗ')}</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button block shape="round" className={classes.btnCancel} onClick={onReset}> {t('ΑΚΥΡΟ')} </Button>
                </Form.Item>
                
                
              </Form>

              </div>
            </Col>
          </Row>
        </div>
    </>
  )
}

export default EditProfile


import React from "react"

const EmailSvg = () => {

    return (

    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <defs>
        <clipPath id="clip-path">
        <rect id="Rectangle_810" data-name="Rectangle 810" width="32" height="32" transform="translate(0.477 0.266)" fill="#063a81"/>
        </clipPath>
    </defs>
    <g id="icon_emai" transform="translate(-0.477 -0.266)">
        <g id="Group_912" data-name="Group 912" clipPath="url(#clip-path)">
        <path id="Path_2597" data-name="Path 2597" d="M9.165,31.755a42.667,42.667,0,0,0,7.015.581,42.667,42.667,0,0,0,7.015-.581,2.02,2.02,0,0,0-.662-3.964,38.8,38.8,0,0,1-12.7,0A6.382,6.382,0,0,1,4.564,22.53a38.666,38.666,0,0,1,0-12.7A6.379,6.379,0,0,1,9.826,4.563a38.8,38.8,0,0,1,12.7,0,6.382,6.382,0,0,1,5.267,5.262,38.69,38.69,0,0,1,.505,6.352V17.44a2.272,2.272,0,0,1-4.545,0V16.176a7.6,7.6,0,1,0-2.389,5.505A6.3,6.3,0,0,0,32.339,17.44V15.167a42.869,42.869,0,0,0-.56-6.006A10.424,10.424,0,0,0,23.193.578a42.862,42.862,0,0,0-14.028,0A10.417,10.417,0,0,0,.58,9.162a42.651,42.651,0,0,0,0,14.03,10.42,10.42,0,0,0,8.585,8.563Zm7.015-12.043a3.536,3.536,0,1,1,2.5-1.036,3.535,3.535,0,0,1-2.5,1.036" transform="translate(0 0)" fill="#063a81"/>
        </g>
    </g>
    </svg>
    )
}

export default EmailSvg
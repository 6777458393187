import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Row, Col, Button, Form, Input, Upload, Space, message } from 'antd'
import classes from "./styles.module.css"
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'

import i18next from 'i18next'
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../components/Helmet'

import type { UploadFile, UploadProps } from 'antd/es/upload/interface'


import type { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'



const CreateAdvertorial: React.FC = () => {

  const { t } = useTranslation()

  const [save, setSave]=useState<boolean>(false)
  const [messageApi, successMessage] = message.useMessage()
  const [messageApiError, errorMessage] = message.useMessage()
  const [form] = Form.useForm()
  let image_Array: any = []
  let pdf_Array: any = []
  const Login: any[] = useSelector((state: RootState) => state.Logged.Login)


  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Saved',
      className: 'custom-class',
      style: {
        marginTop: '20vh',
      },
    });
  };

  const wrong = () => {
    messageApiError.open({
      type: 'error',
      content: 'Something wrong',
      className: 'custom-class',
      style: {
        marginTop: '20vh',
      },
    });
  };

  const layout = { labelCol: { sm: { span: 12 }, md: { span: 12 }, lg: { span: 8 } }, wrapperCol: { sm: { span: 12 }, md: { span: 12 }, lg: { span: 16 } } }

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }


  const onFinish = async (values: any) => {

    setSave(true)

    let image: any = ''
    let link: any = ''
    let pdf: any = ''
    let video: any = ''

    if (values.ArrayImages !== undefined) {

      values.ArrayImages.fileList.map((item: any, index: number) => {

        const fileReader = new FileReader()
        fileReader.readAsBinaryString(item.originFileObj)
        const reader = new FileReader()
        reader.readAsArrayBuffer(item.originFileObj)

        reader.onload = () => {

          const image = reader.result;
          
          // const requestOptions = {
          //   method: 'POST',
          //   headers: {  "Accept": "application/vnd.api+json",
          //   "Content-Type": "application/octet-stream",
          //   "Content-Disposition": `file; filename=\"${Math.floor(Math.random() * 100000000)}_${item.name}\"`,
          //   "X-Requested-With": "XMLHttpRequest"
          //   },
          //   body: image
          // }

          // fetch(`https://goint-admin.dotsoft.gr/api/node/advertorial/field_advertorial_photos`, requestOptions)
          // .then(response  =>

          //    console.log(response )
          //     // image_Array.push({
          //     //   "type": "file--file",
          //     //   "id": response.id.toString(),
          //     //   "meta": {
          //     //     "alt": "image Alt",
          //     //     "title": "image Title",
          //     //     "width": null,
          //     //     "height": null
          //     //   }
          //     // })

          // ).catch(err => console.log("err", err))

          axios({
            method: 'post',
            url: `https://goint-admin.dotsoft.gr/api/node/advertorial/field_advertorial_photos`, //API url
            data: image,
            timeout: 2000,
            headers: {
              "Content-Type": "application/octet-stream",
              "Content-Disposition": `file; filename=\"${Math.floor(Math.random() * 100000000)}_${item.name}\"`,
              "X-Requested-With": "XMLHttpRequest",
              "Authorization": `Bearer ${Login[0]}`
              
            }

          }).then(({ data }) => {

            image_Array.push({
              "type": "file--file",
              "id": data.data.id,
              "status": true,
              "meta": {
                "alt": "image Alt",
                "title": "image Title",
                "width": null,
                "height": null
              }
            })
          }).catch(err => console.log("err", err))
        }
      })
    }



    if (values.ArrayLinks !== undefined) {

      values.ArrayLinks.map((item: any, index: number) => link += `{"title":"${item.LinkName}","uri":"${item.LinkUrl}"},`)

      link = link.substr(0, link.length - 1)
      link = `, "field_advertorial_links":[ ` + link + ` ]`
    }


    if (values.ArrayVideos !== undefined) {

      values.ArrayVideos.map((item: any, index: number) => video += `{"title":"${item.VideoName}","uri":"${item.VideoUrl}"},`)

      video = video.substr(0, video.length - 1)
      video = ` ,"field_advertorial_videos":[ ` + video + ` ]`
    }


    if (values.ArrayPdf !== undefined) {

      values.ArrayPdf.fileList.map((item: any, index: number) => {

        const reader = new FileReader()
        reader.readAsArrayBuffer(item.originFileObj)
        reader.onload = () => {

          const pdf = reader.result;

          axios({
            method: 'post',
            url: `https://goint-admin.dotsoft.gr/api/node/advertorial/field_advertorial_files`, //API url
            data: pdf,
            headers: {
              "Accept": "application/vnd.api+json",
              "Content-Type": "application/octet-stream",
              "Content-Disposition": `attachment; filename=\"${Math.floor(Math.random() * 100000000)}_${item.name}\"`,
              "X-Requested-With": "XMLHttpRequest",
              "Authorization": `Bearer ${Login[0]}`

            }
          }).then(({ data }) =>

            pdf_Array.push({
              "type": "file--file",
              "id": data.data.id.toString()
            })

          ).catch(err => console.log("err", err))

        }
      })

    }// End check if not null PDF


    setTimeout(() => {

      if (image_Array[0] !== '') {

        image_Array.map((item: any) => image += `{ "type": "file--file", "id": "${item.id}",
          "meta": {
            "alt": "adv img alt",
            "title": "adv img title",
            "width": null,
            "height": null
          } },`)

        image = image.substr(0, image.length - 1)
        image = `"field_advertorial_photos": {
                    "data": [ ` + image + `]
                   }`
      }



      if (pdf_Array[0] !== '') {

        pdf_Array.map((item: any) => pdf += `{ "type": "file--file", "id": "${item.id}" },`)

        pdf = pdf.substr(0, pdf.length - 1)
        pdf = `,"field_advertorial_files": {
                    "data": [ ` + pdf + `]
                   }`
      }

      const url = 'https://goint-admin.dotsoft.gr/api/node/advertorial'
      const headers = {
        "Content-type": "application/vnd.api+json",
        "Accept": "application/vnd.api+json",
        "Authorization": `Bearer ${Login[0]}` 
      }

      const data = `
          {
            "data": {
              "type": "node--advertorial",
              "attributes": {
                "title": "${values.title !== undefined ? values.title : 'Title'}",
                "field_advertorial_description":"${values.description !== undefined ? values.description : 'Description'}"`
                  + link + video +
                  `},
              "relationships": {`
                + image + pdf +
                `}
            }
          }`;


      axios({
        method: 'post',
        url: url,
        data: data,
        timeout: 200000,
        headers: headers
      }).then((response) => [
        setSave(false),
        success(),
        form.resetFields(),
        window.scrollTo(0, 0)])
        .catch(err => console.log("err", err));

    }, 4000)

  };


  const onReset = () => {
    form.resetFields();
  }



  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileListPdf, setFileListPdf] = useState<UploadFile[]>([]);



  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );


  const props: UploadProps = {

    onRemove: (file) => {

      const index = fileListPdf.indexOf(file);
      const newFileList = fileListPdf.slice();
      newFileList.splice(index, 1);
      setFileListPdf(newFileList);
    },

    beforeUpload: (file) => {

      const isLt1M = file.size / 1024 / 1024 < 1;

      if (!isLt1M) {
        message.error('File must smaller than 1MB!');

        // const index = fileListPdf.indexOf(file);
        // const newFileList = fileListPdf.slice();
        // newFileList.splice(index, 0);
        // setFileListPdf(newFileList);
        // console.log('fileList',fileListPdf )
        return
      }
      setFileListPdf([...fileListPdf, file]);

      return false;
    },
    // fileListPdf,
  }


  return (
    <>
      <span>
        {successMessage}
      </span>
      <span> 
        {errorMessage}
      </span>
      <HelmetComponent title='Create Advertorial' descrition='Create Advertorial' />
      <div className={classes.container}>
        <Row justify="center">
          <Col xs={{ span: 24 }} lg={{ span: 20 }}>
            <div className={classes.container}>

              <div className={classes.titleMain}><span>{t('Καταχώρηση σε Advertorial')}</span></div>

              <Form
                {...layout}
                form={form}
                name="nest-messages"
                onFinish={onFinish}
                style={{ textAlign: 'right' }}
                validateMessages={validateMessages}
              >

                <span>

                  <Form.Item name='ArrayImages'
                    className={classes.item}
                    label={<label className={classes.itemName}>Images</label>} >

                    <Upload
                      listType="picture-card"
                      // fileList={[]}
                      // beforeUpload={beforeUpload}
                      // onPreview={handlePreview}
                      // onChange={handleChange}
                      {...props}
                      accept="image/png, image/jpeg, image/jpg"
                      multiple={true}
                      maxCount={20}
                    >
                      {uploadButton}
                    </Upload>
                    {/* <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal> */}

                  </Form.Item>


                  <Form.Item name='title'
                    className={classes.item}
                    label={<label className={classes.itemName}>{t('Τίτλος')}</label>}>
                    <Input className={classes.input} />
                  </Form.Item>



                  <Form.Item name='description'
                    className={classes.item}
                    label={<label className={classes.itemName}>{t('Κείμενο')}</label>}>
                    <Input.TextArea className={classes.input} rows={8} />
                  </Form.Item>



                  <Form.List name="ArrayLinks" >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (

                          <div key={key}>
                            <Form.Item
                              {...restField}
                              name={[name, 'LinkName']}
                              className={classes.item}
                              label={<label className={classes.itemName}>Link Name</label>}>
                              <Input className={classes.input90} />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'LinkUrl']}
                              className={classes.item}
                              label={<label className={classes.itemName}>Link Url</label>}
                              rules={[
                                {
                                  type: "url",
                                  message: "This field must be a valid url."
                                }
                              ]}
                            >
                              <Input className={classes.input90} />

                            </Form.Item>

                            <MinusCircleOutlined onClick={() => remove(name)} style={{ position: 'relative', top: '-70px' }} />
                          </div>

                        ))}
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} className={classes.input}>
                            Add Link
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>



                  <Form.Item name='ArrayPdf'
                    className={classes.item}
                    label={<label className={classes.itemName}>Pdf (File size Max 1mb)</label>}>

                    <Upload
                      {...props}
                      accept='application/pdf'
                    >
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                  </Form.Item>



                  <Form.List name="ArrayVideos" >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <div key={key}>
                            <Form.Item
                              {...restField}
                              name={[name, 'VideoName']}
                              className={classes.itemVideo}
                              label={<label className={classes.itemName}>Video Name</label>}>
                              <Input className={classes.input90} />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'VideoUrl']}
                              className={classes.itemVideo}
                              label={<label className={classes.itemName}>Video Link</label>}
                              rules={[
                                {
                                  type: "url",
                                  message: "This field must be a valid url."
                                }
                              ]}>
                              <Input className={classes.input90} />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} style={{ position: 'relative', top: '-70px' }} />
                          </div>
                        ))}
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} className={classes.input}>
                            Add Video Link
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>

                  {/* <Form.Item wrapperCol={{ xs: { span: 18, offset: 0 }, sm: { span: 4, offset: 16 }, md: { span: 4, offset: 16 } }}> */}
                  <Form.Item wrapperCol={{ md: { span: 23 }, lg: { span: 16 } }} name="save">
                    <>
                      <Button type="primary" shape="round" htmlType="submit" loading={save} className={save ? classes.btnSaveCancel : classes.btnSave}>{t('ΑΠΟΘHΚΕΥΣΗ')}</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                      <Button block shape="round" className={classes.btnCancel} onClick={onReset}> {t('ΑΚΥΡΟ')} </Button>
                    </>
                  </Form.Item>

                </span>
              </Form>

            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default CreateAdvertorial

import React, { useState, useEffect, useMemo, useCallback }  from 'react'
import { Row, Col } from 'antd'
import classes from "./styles.module.css"

import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons"
import i18next from 'i18next'
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../components/Helmet'

import { ExportDirectoryProps } from "../../services/props"
import { useExportDirectory } from '../../hooks/useApi'


import Collapsible from 'react-collapsible';
import CompanyName from '../../img/svg/company'
import ActivitySvg from '../../img/svg/activity'
import ContactPersonSVG from '../../img/svg/contactPerson'
import ProductsSvg from '../../img/svg/products'

import TelSvg from '../../img/svg/tel'
import EmailSvg from '../../img/svg/email'
import WebsiteSvg from '../../img/svg/website'
import SocialMediaSvg from '../../img/svg/socialMedia'


const ExportDirectory: React.FC = () => {

  const { t } = useTranslation()
  
  const { isFetching, ExportDirectory, refetch } = useExportDirectory()

  useEffect(() => {
    refetch()
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  }, [i18next.language])


  const Directory = ({ data }: any ) => {
    
    const [view, setView] = useState<boolean>(false)

    return (
      <div className={classes.box}>
        <Row>
          <Col xs={24} sm={24} md={6} lg={4}>
            <span className={view ? classes.rowImageWithYoutBorder : classes.rowImage}>
              <img src={data.logo} alt={ data.alt } />
            </span>

          </Col>
          <Col xs={24} sm={24} md={18} lg={20}>

            <Collapsible open={view} handleTriggerClick={() => setView(!view)} trigger={
              <div className={classes.divVasiko}>

                <span className={classes.rowTextPart1}>
                  <span> {data.title}</span>
                </span>

                <span className={classes.rowTextPart2}>

                  {view ?
                    <ArrowUpOutlined style={{ color: 'white', fontSize: '38px' }} />

                    :
                    <ArrowDownOutlined style={{ color: 'white', fontSize: '38px' }} />
                  }

                </span>
              </div>
            }>

              <div className={classes.collapseBackGround}>
                <Row>
                  <Col xs={24} sm={24} md={12} lg={12}>

                    <div>
                      <span className={classes.svg}>
                        <ActivitySvg />
                      </span>
                      <span className={classes.title}>
                        Activity
                      </span>
                      <span className={classes.text}>
                        {data.activity}
                      </span>
                    </div>

                    <div>
                      <span className={classes.svg}>
                        <ContactPersonSVG />
                      </span>
                      <span className={classes.title}>
                        Contact person
                      </span>
                      <span className={classes.text}>
                        {data.contact_person}
                      </span>
                    </div>

                    <div>
                      <span className={classes.svg}>
                        <ProductsSvg />
                      </span>
                      <span className={classes.title}>
                        Products
                      </span>
                      <span className={classes.text}>
                        {data.products}
                      </span>
                    </div>

                   

                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>

                    <div>
                      <span className={classes.svg}>
                        <CompanyName />
                      </span>
                      <span className={classes.title}>
                        Address
                      </span>
                      <span className={classes.text}>
                        {data.address}
                      </span>
                    </div>

                    <div>
                      <span className={classes.svg}>
                        <TelSvg />
                      </span>
                      <span className={classes.title}>
                        Tel
                      </span>
                      <span className={classes.text}>
                        {data.telephone}
                      </span>
                    </div>

                    <div>
                      <span className={classes.svg}>
                        <EmailSvg />
                      </span>
                      <span className={classes.title}>
                        E-mail
                      </span>
                      <span className={classes.text}>
                        {data.email}
                      </span>
                    </div>

                    <div>
                      <span className={classes.svg}>
                        <WebsiteSvg />
                      </span>
                      <span className={classes.title}>
                        Website
                      </span>
                      <div className={classes.textWebUrl}>
                        <a href={data.website} target="_blank">{data.website}</a>
                      </div>
                    </div>

                    <div>
                      <span className={classes.svg}>
                        <SocialMediaSvg />
                      </span>
                      <span className={classes.title}>
                        Social media
                      </span>
                      <span className={classes.textSocialMedia}>
                        {data.social.map((soc: any, index: number) => {
                        
                          return (
                            <span key={index}>
                              <br /><a href={soc.url} target="_blank">{soc.text}</a>
                            </span>
                          )
                        }
                      )}
                       
                      </span>
                    </div>

                  </Col>
                </Row>
              </div>


            </Collapsible>
          </Col>
        </Row>
      </div>
    )
  }

  if (isFetching || ExportDirectory.length==0) { return <div className={classes.container}></div> }

  return (
    <>
      <HelmetComponent title='SEO Common page title' descrition='SEO Common page description' />
      <div className={classes.backgroundGrey}>
        <div className={classes.container}>
          <Row justify="center">
            <Col  xs={{span:24}} lg={{span : 20}}>
              <div className={classes.container}>
                <div className={classes.titleMain}>Export Directory</div>
                  { ExportDirectory.map((data: ExportDirectoryProps, index: number) =>  <span key={index}><Directory data={data} /> </span> ) }
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default ExportDirectory

export const FundingProgramsData = [{
  'gr':{
  id: 1,
  title: 'Το πρόγραμμα χρηματοδότησης',
  text: `
  <p>Το Πρόγραμμα Συνεργασίας "Ελλάδα-Βουλγαρία 2014-2020" εγκρίθηκε από την Ευρωπαϊκή Επιτροπή στις 09/09/2015 με την απόφαση C(2015)6283. </p> 
  
  <h3>Προϋπολογισμός</h3>
  <p>Ο συνολικός προϋπολογισμός (ΕΤΠΑ και εθνική συνεισφορά) για το Ευρωπαϊκό Εδαφικό Πρόγραμμα "Ελλάδα-Βουλγαρία 2014-2020" ανέρχεται σε 130.262.836,00 € .Η συνολική χρηματοδότηση αποτελείται από 110.723.408 € (85%) χρηματοδότηση από το ΕΤΠΑ και 19.539.428 € (15%) εθνική συνεισφορά. </p> 
  
  <h3>Επιλέξιμη περιοχή</h3>
  <p>Η επιλέξιμη περιοχή του Προγράμματος αποτελείται από την Περιφέρεια Ανατολικής Μακεδονίας και Θράκης (Νομοί Έβρου, Καβάλας, Ξάνθης, Ροδόπης και Δράμας) και την Περιφέρεια Κεντρικής Μακεδονίας (Νομοί Θεσσαλονίκης και Σερρών) στην Ελλάδα και την Νοτιοκεντρική Περιφέρεια Σχεδιασμού και τη Νοτιοδυτική Περιφέρεια Σχεδιασμού (Περιφέρειες Blagoevgrad, Smolyan, Kardjali και Haskovo) στη Βουλγαρία. </p> 
  
  <img style="width: 700px; margin: 20px 20px 20px 0; float: left;" src="../img/img.jpg" alt="Image program"/>
  
  <p style="display: inline;">Η διασυνοριακή επιλέξιμη περιοχή Ελλάδας-Βουλγαρίας για την προγραμματική περίοδο 2014-2020 εκτείνεται σε 40.202 km2 και έχει συνολικό πληθυσμό 2,7 εκατομμύρια κατοίκους. Καλύπτει τέσσερις εδαφικές ενότητες σε επίπεδο NUTS II (Περιφέρειες) και 11 εδαφικές ενότητες σε επίπεδο NUTS III (Περιφέρειες). Η επιλέξιμη περιοχή εκτείνεται σε ολόκληρη την ελληνοβουλγαρική μεθόριο και γειτνιάζει με την Τουρκία (ανατολικά) και την ΠΓΔΜ (δυτικά), δύο χώρες που φιλοδοξούν να αποκτήσουν πρόσβαση στην ΕΕ. Αποτελεί μέρος της νοτιοανατολικότερης μη νησιωτικής περιοχής της ΕΕ και βρίσκεται μεταξύ τριών θαλασσών: της Μαύρης Θάλασσας, της Μεσογείου και του Ιονίου-Αδριατικής Θάλασσας. Τέλος, βρίσκεται στο σταυροδρόμι των στρατηγικών αγωγών ορυκτών καυσίμων που τροφοδοτούν την αγορά της ΕΕ και τους άξονες μεταφορών των ΔΕΔ.</p> 
  <p>Η οικιστική δομή της περιοχής χαρακτηρίζεται από την παρουσία 10 μεσαίων-μεγάλων πόλεων (>50.000 κατοίκων) που συγκεντρώνουν το 38,2% του συνολικού πληθυσμού, και 25 μικρών πόλεων (10.000-50.000 κατοίκων).</p> 
  <p>Παρά τα ιστορικά σχετικά μικρά ποσά των διατιθέμενων κονδυλίων, υπάρχει μια μακρά ιστορία συνεργασίας στην επιλέξιμη περιοχή, η οποία ξεκίνησε με την κοινοτική πρωτοβουλία INTERREG I (1989-1993).</p> 
  
  <p><strong style="font-weight:bold;">Οι άξονες προτεραιότητας είναι:</strong></p>
  
  <strong style="font-weight:bold;">ΠΕ 1: </strong>Μια ανταγωνιστική και προωθητική για την επιχειρηματικότητα διασυνοριακή περιοχή<br/>
  <strong style="font-weight:bold;">ΠΕ 2: </strong>Μια βιώσιμη και προσαρμόσιμη στην κλιματική αλλαγή διασυνοριακή περιοχή<br/>
  <strong style="font-weight:bold;">ΠΕ 3: </strong>Μια καλύτερα διασυνδεδεμένη διασυνοριακή περιοχή<br/>
  <strong style="font-weight:bold;">ΠΕ 4: </strong>Μια διασυνοριακή περιοχή χωρίς κοινωνικούς αποκλεισμούς<br/>
  <strong style="font-weight:bold;">ΣΠ 5: </strong>Τεχνική βοήθεια<br/>
  `
  },
  'en':{
  id: 2,
  title: 'The funding programme',
  text: `
  <p>The Cooperation Programme “Greece-Bulgaria 2014-2020” was approved by the European Commission on 09/09/2015 by Decision C(2015)6283.</p>
   
  <h3>Budget</h3>
  <p>The total budget (ERDF and national contribution) for the European Territorial Programme “Greece-Bulgaria 2014-2020” is €130,262,836.00 .The total financing consists of €110.723.408 (85%) ERDF funding and €19.539.428 (15%) national contribution.</p>
  
  <h3>Eligible area</h3>
  <p>The eligible area of the Programme consists of the Region of Eastern Macedonia and Thrace (Prefectures of Evros, Kavala, Xanthi, Rodopi and Drama) and the Region of Central Macedonia (Prefectures of Thessaloniki and Serres) in Greece and the South-Central Planning Region and South-West Planning Region (Districts of Blagoevgrad, Smolyan, Kardjali and Haskovo) in Bulgaria.</p>
  <img style="width: 700px; margin: 20px 20px 20px 0; float: left;" src="../img/img.jpg" alt="Image program"/>
  <br/>
  <p style="display: inline;">The Greece-Bulgaria cross-border eligible area for the programming period 2014-2020 extends to 40.202 km2 and has a total population of 2.7 million inhabitants. It covers four territorial units at NUTS II level (Regions), and 11 territorial units at NUTS III level (Districts). The eligible area extends across the entire Greek-Bulgarian border and is neighbouring with Turkey (east) and FYROM (west), both countries aspiring to access to the EU. It is part of the most south-eastern non-insular area of EU, and it is situated between three seas: the Black Sea, the Mediterranean Sea and the Ionian-Adriatic Sea. Finally, it sits at the crossroad of strategic fossil fuel pipelines supplying the EU market and TEN transport axes.</p>
  <p>The settlement structure of the area is characterized by the presence of 10 medium-large cities (>50.000 inhabitants) which accumulate 38,2% of total population, and 25 small cities (10.000-50.000 inhabitants).</p>
  <p>Despite the historically relatively small amounts of funds allocated, there is a long history of cooperation in the eligible area, which started with Community initiative INTERREG I (1989-1993).</p>
  <br/>
  
  <p><strong style="font-weight:bold;">The priority axes are:</strong></p>
  <strong style="font-weight:bold;">PA 1: </strong>A Competitive and Entrepreneurship Promoting Cross-Border Area<br/>
  <strong style="font-weight:bold;">PA 2: </strong>A Sustainable and climate adaptable Cross-Border area<br/>
  <strong style="font-weight:bold;">PA 3: </strong>A better interconnected Cross-Border Area<br/>
  <strong style="font-weight:bold;">PA 4: </strong>A socially inclusive Cross-Border area<br/>
  <strong style="font-weight:bold;">PA 5: </strong>Technical Assistance<br/>
    `
  },
  'bg':{
  id: 3,
  title: 'Програмата за финансиране',
  text: `
  <p>Програмата за сътрудничество „Гърция-България 2014-2020“ е одобрена от Европейската комисия на 09.09.2015 г. с Решение C(2015)6283.</p>
  
  <h3>Бюджет</h3>
  <p>Общият бюджет (ЕФРР и национален принос) за Европейската териториална програма „Гърция-България 2014-2020“ е €130 262 836,00. Общото финансиране се състои от €110 723 408 (85%) финансиране от ЕФРР и €19 539 428 (15%) национален принос.</p>
  
  <h3>Допустима площ</h3>
  
  <p>Допустимата област на програмата се състои от регион Източна Македония и Тракия (префектури Еврос, Кавала, Ксанти, Родопи и Драма) и регион Централна Македония (префектури Солун и Серес) в Гърция и Южен централен регион за планиране и Югозападен район за планиране (области Благоевград, Смолян, Кърджали и Хасково) в България.</p>
  <img style="width: 700px; margin: 20px 20px 20px 0; float: left;" src="../img/img.jpg" alt="Image program"/>
  <p style="display: inline;">Трансграничната допустима зона Гърция-България за програмния период 2014-2020 г. се простира на 40,202 km2 и има общо население от 2,7 милиона жители. Той обхваща четири териториални единици на ниво NUTS II (региони) и 11 териториални единици на ниво NUTS III (области). Допустимата зона се простира през цялата гръцко-българска граница и граничи с Турция (изток) и РСМ (запад), като и двете страни се стремят към достъп до ЕС. Той е част от най-югоизточната неостровна зона на ЕС и е разположен между три морета: Черно море, Средиземно море и Йонийско-Адриатическо море. И накрая, той се намира на кръстопътя на стратегически тръбопроводи за изкопаеми горива, доставящи пазара на ЕС, и транспортните оси на TEN.</p>
  <p>Селищната структура на района се характеризира с наличието на 10 средно големи града (>50 000 жители), които натрупват 38,2% от общото население, и 25 малки града (10 000-50 000 жители).</p>
  <p>Въпреки исторически сравнително малките суми на разпределените средства, съществува дълга история на сътрудничество в допустимата област, която започна с инициативата на Общността INTERREG I (1989-1993 г.).</p>
  
  <p><strong style="font-weight:bold;">Приоритетните оси са:</strong></p>
  <strong style="font-weight:bold;">PA 1: </strong>Конкурентоспособна и насърчаваща предприемачеството трансгранична зона<br/>
  <strong style="font-weight:bold;">PA 2: </strong>Устойчива и адаптивна към климата трансгранична зона<br/>
  <strong style="font-weight:bold;">PA 3: </strong>По-добра взаимосвързана трансгранична зона<br/>
  <strong style="font-weight:bold;">PA 4: </strong>Социално приобщаваща трансгранична зона<br/>
  <strong style="font-weight:bold;">PA 5: </strong>Техническа помощ<br/>
  `
  }
  }]
  
import React, { useState } from "react"
import { useQuery } from '@tanstack/react-query'
import { UserProps } from "../services/props"
import { user } from "../services/user"



import type { RootState } from '../redux/store'
import { useSelector } from 'react-redux'




export const useUserLogin = (email: string, password: string) => {
  
  const [User, setUser] = useState<any>(); 


  const { status, error, refetch } = useQuery(['login', email, password], () => user.loginUser(email, password), {

    refetchOnWindowFocus: false,
    enabled: false,

    onSuccess: (data) => {
     
      setUser(data.data.user)
      
    },
    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { status, error, User, refetch }
}

export const useUserRegister = (username: string, email: string, password: string) => {
  
  const [Message, setMessage] = useState<string>(''); 

  const { refetch } = useQuery(['register', username, email, password], () => user.registerUser(username, email, password), {

    refetchOnWindowFocus: false,
    enabled: false,

    onSuccess: (data) => {
      setMessage(data.message)
    },
    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { Message, refetch }
}


export const useUserProfile = () => {
  
  const [User, setUser] = useState<any>()
  
  let UserArray:any = {}


  const Login: any[] = useSelector((state: RootState) => state.Logged.Login)

  const { isLoading, isFetching } = useQuery(['getProfile'], () => user.getUserProfile( localStorage.getItem('access_token') !== null ? localStorage.getItem('access_token') : Login[0] ), {

    onSuccess: (data:any) => {

      UserArray = {
        id: data.id,
        name: data.name,
        email: data.mail,
        company: data.export_directory,
      }

      setUser(UserArray)
    },
    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, User }
}

import React from 'react'
import { Row, Col, Carousel } from 'antd'
import classes from "./css/Slider.module.css"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import { SampleNextArrow, SamplePrevArrow } from '../../../components/ArrowsForCarousel/Slider'

const Slider: React.FC = () => {

  const { t } = useTranslation()

  const settings = {
    dots: false,
    arrows:true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1056,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 5,
          infinite: true,
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }  

  return (
    <>
      <div className={classes.container}>
        <Row justify="center">

          <Col xs={24} sm={12} md={14} lg={14}>
            {/* <div className={classes.fillLogo}>
              <img src='../img/Slider_logo.jpg' alt="image Slider Logo" />
            </div> */}
            <div className={classes.wrapperContainer}>
              <div className={classes.wrapper}>
                <div className={classes.a} >{t('Slider_Title')}</div>
                <div className={classes.b} >{t('Slider_Text')}</div>
                <div className={classes.c} >
                  <Link to='page/about_project' state={{ page:'about_project' }} className={classes.c1} >{t('Διαβάστε περισσότερα')}</Link>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12} md={10} lg={10}>

            <Carousel autoplay {...settings}>
              <div className={[classes.fill, classes.hide].join(" ")}>
                <img src='../img/Slider.jpg' alt="image Slider" />
              </div>
              <div className={[classes.fill, classes.hide].join(" ")}>
                <img src='../img/Slider2.jpg' alt="image Slider2" />
              </div>
              <div className={[classes.fill, classes.hide].join(" ")}>
                <img src='../img/Slider3.jpg' alt="image Slider2" />
              </div>
            </Carousel>

          </Col>

        </Row>
      </div>
    </>
  )
}

export default Slider
